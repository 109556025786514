import HashMap from 'hashmap';

//Cards
import BeNice from './images/BaseCards/BeNice.png';
import BuilderPepe from './images/BaseCards/BuilderPepe.png';
import FeelsBadMan from './images/BaseCards/FeelsBadMan.png';
import PepeJammer from './images/BaseCards/PepeJammer.png';
import SpoungePepe from './images/BaseCards/SpoungePepe.png';

import Boomer from './images/BaseCards/Boomer.png';
import Doomer from './images/BaseCards/Doomer.png';
import NPC from './images/BaseCards/NPC.png';
import Rope from './images/BaseCards/Rope.png';
import Sonicac from './images/BaseCards/Sonicac.png';

import Smug from './images/BaseCards/Smug.png';
import Soyjack from './images/BaseCards/Soyjack.png';
import TheMerchant from './images/BaseCards/TheMerchant.png';
import ToiletPepe from './images/BaseCards/ToiletPepe.png';
import WojakPepe from './images/BaseCards/WojakPepe.png';

import Blackjack from './images/BaseCards/Blackjack.png';
import Copium from './images/BaseCards/Copium.png';
import Despair from './images/BaseCards/Despair.png';
import Grug from './images/BaseCards/Grug.png';
import PepePunch from './images/BaseCards/PepePunch.png';

import Rage from './images/BaseCards/Rage.png';
import Wizard from './images/BaseCards/Wizard.png';
import Chad from './images/BaseCards/Chad.png';
import Monka from './images/BaseCards/Monka.png';
import PepeRoss from './images/BaseCards/PepeRoss.png';

import PepeTrump from './images/BaseCards/PepeTrump.png';
import Bob from './images/BaseCards/Bob.png';
import Hopium from './images/BaseCards/Hopium.png';
import BabySharkPepe from './images/BaseCards/BabySharkPepe.png';
import BatmanPepe from './images/BaseCards/BatmanPepe.png';

import FrenchPepe from './images/BaseCards/FrenchPepe.png';
import Hitorigoto from './images/BaseCards/Hitorigoto.png';
import JigsawPepe from './images/BaseCards/JigsawPepe.png';
import PatrickPepe from './images/BaseCards/PatrickPepe.png';
import PepelonMusk from './images/BaseCards/PepelonMusk.png';

import PepeTeeth from './images/BaseCards/PepeTeeth.png';
import PepeWhy from './images/BaseCards/PepeWhy.png';
import RhastaPepe from './images/BaseCards/RhastaPepe.png';
import WendysPepe from './images/BaseCards/WendysPepe.png';




//Backgrounds
import GamePageBackgroundImg from "../assets/images/Backgrounds/10.webp";
import LorePageBackgroundImg from "../assets/images/Backgrounds/9.webp";
import StorePageBackgroundImg from "../assets/images/Backgrounds/8.webp";
import InventoryBG from "../assets/images/Backgrounds/7.webp";
import ProfileBG from "../assets/images/Backgrounds/6.webp";
import DeckPageBG from "../assets/images/Backgrounds/5.webp";
import HowHomeBg from "../assets/images/Backgrounds/4.webp"
import HowHomeBgMobile from "../assets/images/Backgrounds/3.webp"
import GuidePageBG from "../assets/images/Backgrounds/2.webp"

//Website Art Assets
import SlotBorder from "../assets/images/SlotBorder.png";
import AppbarLogoFull from "../assets/images/appbar-logo-full.png"
import AppbarLogoMedium from "../assets/images/appbar-logo-medium.png"
import AppbarLogoSmall from "../assets/images/appbar-logo-small.png"

//Homepage art assets
import DeckBack from "../assets/images/deck-back.png"


//world map bgs

import Bg1 from "../assets/images/Backgrounds/1.webp"
import Bg2 from "../assets/images/Backgrounds/2.webp"
import Bg3 from "../assets/images/Backgrounds/3.webp"
import Bg4 from "../assets/images/Backgrounds/4.webp"
import Bg5 from "../assets/images/Backgrounds/5.webp"
import Bg6 from "../assets/images/Backgrounds/6.webp"
import Bg7 from "../assets/images/Backgrounds/7.webp"
import Bg8 from "../assets/images/Backgrounds/8.webp"
import Bg9 from "../assets/images/Backgrounds/9.webp"
import Bg10 from "../assets/images/Backgrounds/10.webp"


// pepe section art
import Tax from "../assets/images/SectionBgs/tax.png"
import Safe from "../assets/images/SectionBgs/safe.png"
import Ecosystem from "../assets/images/SectionBgs/ecosystem.png"
import Upgrade from "../assets/images/SectionBgs/upgrade-nfts.webp"
import GameToEarn from "../assets/images/SectionBgs/play-to-earn.webp"
import Tournament from "../assets/images/SectionBgs/tournament.webp"
import Stake from "../assets/images/SectionBgs/stake-nfts.webp"

const CardArtProvider = () => {
    const artMap = new HashMap()

    //Base cards
    artMap.set("KWEJ30AU", BeNice)
    artMap.set("1KUYULMS", BuilderPepe)
    artMap.set("N812R2J3", FeelsBadMan)
    artMap.set("9VKOAC1B", PepeJammer)
    artMap.set("6SB9HFRM", SpoungePepe)
    artMap.set("RW5SAA2F", Boomer)
    artMap.set("86FF41XP", Doomer)
    artMap.set("3ZE7VFVC", NPC)
    artMap.set("NZTOZC3I", Rope)
    artMap.set("4RTAFYAY", Sonicac)

    artMap.set("G1CMFM3G", Smug)
    artMap.set("V5LTYW81", Soyjack)
    artMap.set("L7I7FSTZ", TheMerchant)
    artMap.set("042BLLN2", ToiletPepe)
    artMap.set("BTS3CWW7", WojakPepe)
    artMap.set("5D630E4V", Blackjack)
    artMap.set("VKZHVXQ9", Copium)
    artMap.set("Y496ZDSX", Despair)
    artMap.set("NCPW96W2", Grug)
    artMap.set("7MX9ZCYN", PepePunch)

    artMap.set("NIDDE81K", Rage)
    artMap.set("3J05WNR2", Wizard)
    artMap.set("J0JJPZWP", Chad)
    artMap.set("PY6AEXJ3", Monka)
    artMap.set("K2RGW909", PepeRoss)
    artMap.set("L8BQT0T4", PepeTrump)
    artMap.set("1NGYW91Q", Bob)
    artMap.set("E7MEPSOG", Hopium)
    artMap.set("1AN2JDGO", BabySharkPepe)
    artMap.set("J7WWVNXJ", BatmanPepe)

    artMap.set("G1X1LNXH", FrenchPepe)
    artMap.set("V9NA5ODI", Hitorigoto)
    artMap.set("OD6GJTHK", JigsawPepe)
    artMap.set("KUD3MLSJ", PatrickPepe)
    artMap.set("W43KUVTL", PepelonMusk)
    artMap.set("FODM28YE", PepeTeeth)
    artMap.set("O8VGO4D6", PepeWhy)
    artMap.set("EVSW7CK2", RhastaPepe)
    artMap.set("FHXW5PF4", WendysPepe)



    //Backgrounds
    artMap.set("GamePageBG", GamePageBackgroundImg)
    artMap.set("LorePageBG", LorePageBackgroundImg)
    artMap.set("StorePageBG", StorePageBackgroundImg)
    artMap.set("InventoryBG", InventoryBG )
    artMap.set("ProfileBG", ProfileBG)
    artMap.set("DeckPageBG", DeckPageBG)
    artMap.set("HowHomeBg", HowHomeBg)
    artMap.set("HowHomeBgMobile", HowHomeBgMobile)
    artMap.set("GuidePageBG", GuidePageBG)
    

    //Website Art Assets
    artMap.set("SlotBorder", SlotBorder)
    artMap.set("AppbarLogoFull", AppbarLogoFull)
    artMap.set("AppbarLogoMedium", AppbarLogoMedium)
    artMap.set("AppbarLogoSmall", AppbarLogoSmall)
    
    //Homepage Art Assets
    artMap.set("DeckBack", DeckBack)





    //card id to card image mapping
    artMap.set("unanounced1", DeckBack)
    artMap.set("unanounced2", DeckBack)
    artMap.set("unanounced3", DeckBack)
    artMap.set("unanounced4", DeckBack)
    artMap.set("unanounced5", DeckBack)


    //pepe bgs
    artMap.set("Bg1", Bg1)
    artMap.set("Bg2", Bg2)
    artMap.set("Bg3", Bg3)
    artMap.set("Bg4", Bg4)
    artMap.set("Bg5", Bg5)
    artMap.set("Bg6", Bg6)
    artMap.set("Bg7", Bg7)
    artMap.set("Bg8", Bg8)
    artMap.set("Bg9", Bg9)
    artMap.set("Bg10", Bg10)


    // Section art
    artMap.set("Tax", Tax)
    artMap.set("Safe", Safe)
    artMap.set("Ecosystem", Ecosystem)
    artMap.set("Upgrade", Upgrade)
    artMap.set("GameToEarn", GameToEarn)
    artMap.set("Tournament", Tournament)
    artMap.set("Stake", Stake)
    
    
    
    function getArt(artName) {
        return artMap.get(artName)
    }

    return ({
        getArt: getArt
    })
}

export default CardArtProvider
