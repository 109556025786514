import HashMap from 'hashmap';

const CONFIG = {
    //The endpoint used for the calls to the asp net api.
    API_ENDPOINT : "https://api.pepevspepe.vip/api",

    //The base URL for our frontend.
    BASE_URL : "https://pepevspepe.vip/",

    //The ethereum node used for web3.
    ETH_NODE : "https://mainnet.infura.io/v3/1870be68d90148fdb919ea7a1a115973",

    SUPPORTED_CHAINS: 
        new HashMap(
            [
                '1', {
                    name: 'Mainnet', 
                    chainId: '1',
                    hexChainId: '1', 
                    node: [ 'https://mainnet.infura.io/v3/1870be68d90148fdb919ea7a1a115973'], 
                    browser: 'https://etherscan.io',
                    nativeCurrency: {
                        name: 'Ethereum',
                        symbol: 'ETH',
                        decimals: 18
                    }
                },
            ]
        ),
    
    //Game Server URL
    GAME_SERVER_ENDPOINT : 'wss://gameserver.pepevspepe.vip/',

    CORS_ORIGIN : 'https://pepevspepe.vip/',
    
    //Enables client-side features only meant for debugging and development
    DEBUG_MODE : false,

    //Switch Price between Actual and TBD
    SHOW_PRICE: true
}

export default CONFIG;