import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useStore } from '../helpers/GlobalStore'
import { useAoeApiClient } from '../helpers/AoeApiClient'
import { 
    Grid, Modal, CircularProgress, Typography, 
    makeStyles, FormControl, Select, MenuItem 
} from '@material-ui/core'
import SvgButton from './SvgButton'
var HashMap = require('hashmap');

const DeckSelector = ({isDebugMode}) => {
    
    const SHOW_LOADER_MESSAGE = 0;
    const SHOW_DROPDOWN = 1;

    const styles = useStyles();
    const history = useHistory();
    const { getValidDecks, verifyDeckById } = useAoeApiClient();
    const { validDecks, setValidDecks, setSelectedGameDeck, setChildUpdateNeeded } = useStore();
    const [ popoverState, setPopoverState ] = useState(false);
    const [ getDecksCalled, setGetDecksCalled ] = useState(false);
    const [ deckValid, setDeckValid ] = useState(false);
    const [ deckIndex, setDeckIndex ] = useState(0);
    const [ displayError, setDisplayError ] = useState(false);

    const LOADING_DECKS_MSG = "Loading Your Squads";
    const VALIDATING_DECK_MSG = "Verifying Selected Squad";
    const DECK_INVALID_ERROR_MSG = 
        "This squad includes one or more cards that you no longer own. Please " +
        "go back to your squad page to update it or select a different squad.";
    const YOU_HAVE_NO_DECKS_MSG = 
        "You have not created any squads. Go to the Squads page to create a squad.";

    // The Deck Selection process is as follows:
    // 0 => Show Loader with message: "Loading Available Decks"
    // 1 => User Has Decks:
    //      a) Show Deck Selection Drop Down
    //                      OR
    //      b) Show message: "You have not created any decks."
    // 2 => Show Loader with message: "Validating Selected Deck"
    // 3 => Deck Is Valid: 
    //      1) Close Popover and pass back the selected deck details
    // 4 => Deck Is Invalid: 
    //      1) Load Index Display (1)
    //      2) Remove invalid deck from dropdown
    //      3) Show the following error message below drop down list:
    //         "This deck contains one or more cards that you no longer
    //          own. Please go back to your deck page to update it or
    //          select a different deck."
    //      4) Wait for a new selection
    //      5) Hide error on new selection and show Index Display (2)
    // 5 => User Has No decks: Exit back to Game Page
    const [ popoverIndex, setPopoverIndex ] = useState(0);
    const [ processingMsg, setProcessingMsg] = useState(LOADING_DECKS_MSG);

    useEffect(() => {
        return () => {
            setDeckIndex(0);
            setDeckValid(false);
            setGetDecksCalled(false);
            setSelectedGameDeck(0);
            closePopover();
            setValidDecks(new HashMap());
        }
    }, []);

    useEffect(() => {
        if (!getDecksCalled) {
            getDeckDropdownList();
        }
        
    }, [validDecks, getDecksCalled])

    const getDeckDropdownList = async () => {
        await getValidDecks()
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            if (validDecks && validDecks.size > 0) {
                setPopoverIndex(SHOW_DROPDOWN);
            }
            setChildUpdateNeeded(false);
            setGetDecksCalled(true);
        });
    }

    const isDeckValid = async (deckId) => {
        
        setPopoverIndex(SHOW_LOADER_MESSAGE);
        setProcessingMsg(VALIDATING_DECK_MSG);

        // Asumes res is an array of objects with the following properties
        // { cardId: number, uniqueId: string, isValid: boolean }
        let valid = false;
        await verifyDeckById(deckId)
        .then(res => {
            valid = res.every(element => element.isValid);
            setDeckValid(valid);
        })
        .catch(err => {
            console.log(err);
            setDeckValid(false);
        })
        .finally(() => {            
            if (valid) {
                setSelectedGameDeck(deckId);
                setDeckIndex(deckId);
                closePopover();
            } else {
                validDecks.delete(deckId);
                setDeckIndex(0);
                setSelectedGameDeck(0);
                setProcessingMsg(DECK_INVALID_ERROR_MSG);
                setDisplayError(true);
                setPopoverIndex(SHOW_DROPDOWN);
            }
        });
    }

    const displayPopover = async () => {
        setPopoverIndex(SHOW_DROPDOWN);
        setPopoverState(true);
    }

    const closePopover = () => {
        setPopoverIndex(SHOW_LOADER_MESSAGE);
        setPopoverState(false);
        setGetDecksCalled(false);
        setDisplayError(false);
    }

    const displaySelectorMenu = ()  => {
        let popoverElements;
        switch (popoverIndex) {
            case SHOW_LOADER_MESSAGE: {
                popoverElements = (
                    <Grid container className={styles.popContainer} alignItems="center" spacing={2}>
                        <Grid item>
                            <CircularProgress style={{ width: 35, height: 35 }} />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" style={{ color: "white" }}>{processingMsg}</Typography>
                        </Grid>
                    </Grid>
                )
                break;
            }
            case SHOW_DROPDOWN: {
                popoverElements = (
                    <Grid container className={styles.popContainer} alignItems="center" justifyContent="center">
                        <Grid item>
                            <FormControl fullWidth>
                                <Typography className = {styles.textWhite}>Select Your Deck:</Typography>
                                <Select
                                    value={deckIndex}
                                    variant = "outlined"
                                    onChange={(e) => isDeckValid(e.target.value)}
                                    className = { styles.textFieldProp }
                                    inputProps = {{classes: {
                                        icon: styles.textFieldLabel,
                                    }}}

                                    MenuProps={{ classes: { paper: styles.dropdownStyle } }}
                                >
                                { 
                                    validDecks.values().map((validDeck, index) => (
                                        <MenuItem 
                                        className="deckSelectMenuItems" 
                                        key={`deck-${validDeck.deckId}`} 
                                        value={validDeck.deckId}
                                        style={{ backgroundColor: (index % 2 === 0)?'#0a2900':'#051900' }}
                                        >
                                            {validDeck.deckName}
                                        </MenuItem>
                                    ))
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        { !deckValid && displayError &&
                        <Grid item>
                            <Typography variant="h6" style={{ color: "red" }}>{processingMsg}</Typography>
                        </Grid>
                        }
                    </Grid>
                )
                break;
            }
        }
        return popoverElements;
    }


    return (
        <Grid container style={{textAlign:'center', alignItems:'center', margin:'auto'}}>
            {validDecks && validDecks.size > 0 && getDecksCalled 
             ?
                <SvgButton 
                    onClick={() => displayPopover()} 
                    displayText='Select a Squad' 
                    fontSize='18pt' 
                    buttonWidth={350} 
                    buttonHeight={80}
                />
             :
                <SvgButton 
                    onClick={() => history.push('squads')} 
                    displayText='Build a Squad' 
                    fontSize='18pt' 
                    buttonWidth={350} 
                    buttonHeight={80}
                />
            }
            
            <Modal
                open={popoverState}
                onClose={closePopover}
                className={styles.deckSelectorModal}
            >
                {displaySelectorMenu()}
            </Modal>
        </Grid>
    )
}

const useStyles = makeStyles(() => ({
    popContainer: {
        width: '100%',
        height: "auto",
        padding:'10px',
        backgroundColor:'#181c24',
        color:'white',
        margin:'auto'
    },

    textFieldProp: {
        color: "white",
        height: "42px",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    textFieldLabel: {
        fill: "white"
    },

    textWhite: {
        color: "white"
    },

    deckSelectorModal: {
        width: "300px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        margin:'auto',
        padding:'auto'
    },
    dropdownStyle:{
        backgroundColor:'#0a2900'
    }
}))

export default DeckSelector;