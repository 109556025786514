//import { useStore } from "../../helpers/GlobalStore";

var HashMap = require('hashmap');
var MasterCompendiumFile = require('./Master.json');

const CompendiumUtil = () => {

    var MasterCompendium = new HashMap();

    //Walk through the compendium JSON file and store card entries 
    //into a HashMap keyed on the cardId
    for (var i = 0; i < MasterCompendiumFile.length; i++) {
        var cardEntry = MasterCompendiumFile[i];

        MasterCompendium.set(cardEntry.cardId, cardEntry);
    }

    //Will return null if the cardId was not found in the compendium,
    //otherwise will return a copy of the card
    function getCopyOfEntryForCardId(cardId){

        var cardEntry = MasterCompendium.get(cardId);

        if (!cardEntry){
            console.error("No card found in the compendium with cardId: " + cardId);
            return null;
        }

        //Simple (but dumb) javascript way of making copies of simple json objects
        var copyOfEntry = JSON.parse(JSON.stringify(cardEntry));

        return copyOfEntry;
    };

    //Will return an array of copies of all the entries in the compendium
    function getCopiesOfAllEntries(){

        var copiesOfAllEntries = [];

        //Loop through all compendium entries, make copies and add to an array
        var compendiumEntries = MasterCompendium.values();
        for (var i = 0; i < compendiumEntries.length; i++) {
            var cardEntry = compendiumEntries[i];
            var copyOfEntry = JSON.parse(JSON.stringify(cardEntry));
            copiesOfAllEntries.push(copyOfEntry);
        }

        return copiesOfAllEntries;
    };

    //Will return an array of copies of all the entries in the compendium
    function getCompendiumDictionary(){

        var compendiumDictionary = new HashMap();

        //Loop through all compendium entries, make copies and add to an array
        var compendiumEntries = MasterCompendium.values();
        for (var i = 0; i < compendiumEntries.length; i++) {
            var cardEntry = compendiumEntries[i];
            var copyOfEntry = JSON.parse(JSON.stringify(cardEntry));
            copyOfEntry.count = 0;
            copyOfEntry.playable = undefined

            compendiumDictionary.set(copyOfEntry.cardId, copyOfEntry);
        }

        return compendiumDictionary;
    };



    return {
        getCopyOfEntryForCardId: getCopyOfEntryForCardId,
        getCopiesOfAllEntries: getCopiesOfAllEntries,
        getCompendiumDictionary: getCompendiumDictionary
    };

}

export default CompendiumUtil;
