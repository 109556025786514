import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ethers } from 'ethers'
import CONFIG from '../config/config'
import detectEthereumProvider from '@metamask/detect-provider';
import { Web3Provider } from '@ethersproject/providers'

const POLLING_INTERVAL = 12000
const rpcUrl = CONFIG.ETH_NODE
export const INJECTED_CONNECTOR_ID = 0;

export const supportedChainId = CONFIG.CHAIN_ID

export const getSupportedChainId = () => {
  return supportedChainId
}

const injectedConnector = new InjectedConnector({ supportedChainIds: CONFIG.SUPPORTED_CHAINS.values().map(chain=> {return( parseInt(chain.chainId))}) })
export var connnectorObjectArray = [injectedConnector]

export const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider(rpcUrl)

const walletconnect = new WalletConnectConnector({
  rpc: { [supportedChainId]: simpleRpcProvider },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

export const requestNetworkSwitch = async ({selectedChain}) => {

    const provider = await detectEthereumProvider({
      mustBeMetaMask: false
    })
    const lib = await getLibrary(provider)
    if(provider)
      return new Promise(async (resolve, reject) => {
        const hasNetworkSwapped = await provider.request({ method: 'wallet_switchEthereumChain', params:[{chainId: `0x${selectedChain}`}]}).then(res => {
                return res;
            })
            .catch(error => console.log(error))
        resolve(hasNetworkSwapped);
        //provider.on('chainChanged', (_chainId) => window.location.reload());
      })

}

export const connectorObjectsByName = {
  'injected': injectedConnector,
  'walletconnect': walletconnect,
}

// export const getLibrary = async (provider)=> {
  
//   //const provider = await detectEthereumProvider();
//   const library = new ethers.providers.Web3Provider(provider)
//   library.pollingInterval = POLLING_INTERVAL
//   return library
// }

export const getLibrary = (provider) => {
  const library = new Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}

export const signAuthenticationMessage = (publicAddress, nonce, signer, walletType) => {

  let message =
      "Hello! Welcome to Pepe vs Pepe!\n" +
      "Sign this message to prove you have access\n"+
      "to this wallet and we’ll log you in.\n" +
      "This process is crucial to stopping hackers\n"+
      "from using your wallet, and it's free.\n" +
      "Here’s a unique message ID they can’t guess:\n" +
      nonce;

  const localStorage = window.localStorage;
  var localWalletType = walletType;

  if(localWalletType === undefined || localWalletType === null){
      localWalletType = parseInt(localStorage.getItem('userWalletType'))
  }
  
  if (localWalletType === INJECTED_CONNECTOR_ID) {
      //injected wallets like metamask
      return new Promise(async (resolve, reject) => {
          const signature = await signer.signMessage(message).then(res => {
              return res;
          })
          .catch(error => {
            console.log(error.message)
            return (error)
          })

          resolve(signature);
      })

  } else if (localWalletType === WALLET_CONNECT_CONNECTOR_ID) {
      //WalletConnect wallets
      const connector = connnectorObjectArray[localWalletType];
 
      //WalletConnect wallets
      if (connector) {
          return new Promise(async (resolve, reject) => {

              const rpcSigRequest = {
                  method:"personal_sign",
                  params:[
                      message, 
                      publicAddress
                  ],
              }

              const signature = await connector.request(rpcSigRequest).then(res => {
                  const verified = ethers.utils.verifyMessage(message, res);
                  return res;
              }).catch(error => console.log(error.message))

              resolve(signature)
          })
      }
  } else {
      console.log(`Wallet type invalid: ${walletType}`)
      return null;
  }
}

export const disableWalletListeners = (provider) => {
  provider?.removeAllListeners(["networkChanged"])
  provider?.removeAllListeners(["chainChanged"])
  provider?.removeAllListeners(["accountsChanged"])
}

export const enableWalletListeners = (provider, handleChainChange, handleAccountChange) => {
  provider?.on('chainChanged',  (chainId) => {
      handleChainChange(chainId)
  });
  provider?.on('accountsChanged',  (newacct) => {
    handleAccountChange(newacct)
  });
}