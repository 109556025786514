
/*

    ToggleButton has "sides", as many as you provide toggleStates

    toggleStates = [
        {
            artName: "volume-high-icon",
            callback: onMuteClick
        },
        {
            artName: "volume-mute-icon",
            callback: onVolumeLowClick
        },
        {
            artName: "volume-low-icon",
            callback: onVolumeMediumClick
        },
        {
            artName: "volume-medium-icon",
            callback: onVolumeHighClick
        }
    ]

*/
const ToggleButton = (buttonId, scene, scale, position, toggleStates) => {

    var side = 0; //integer used to keep track of which "side" the toggle button is currently on [0 to toggleStates.length]

    var isButtonDisabled = false;

    //Start the toggle button with Side 1 art
    var toggleButton = scene.add.image(position.x, position.y, toggleStates[0].artName)
        .setDepth(position.z)
        .setOrigin(0.5)
        .setScale(scale)
        .setInteractive({ useHandCursor: true });

    //Handles toggling logic for the button
    var onToggleClick = function(){

        //callback of current side
        toggleStates[side].callback();

        //wrap the new index back to zero if it is past the end now
        var nextSide = (side + 1 >= toggleStates.length) ? (0) : (side + 1);

        //set art to the next side
        toggleButton.setTexture(toggleStates[nextSide].artName);

        //change current side to the side we just displayed
        side = nextSide;
    };

    /* We have to do all this pointer up and down bullshit because 
        fucking Phaser 3 doesn't god damn have onClick */
    var buttonDown = false;
    toggleButton.on('pointerdown', function(pointer) {
        if (isButtonDisabled) return;

        buttonDown = true;
    });
    toggleButton.on('pointerout', function(pointer){
        if (isButtonDisabled) return;

        buttonDown = false;
    });
    toggleButton.on('pointerover', function(pointer){
        if (isButtonDisabled) return;
    });
    toggleButton.on('pointerup', function(pointer) {
        if (isButtonDisabled) return;

        if (!buttonDown)
            return;
            
        buttonDown = false;

        onToggleClick();
    });

    function disableButton(){
        isButtonDisabled = true;
        toggleButton.removeInteractive()
    }

    function enableButton(){
        isButtonDisabled = false;
        toggleButton.setInteractive({ useHandCursor: true });
    }

    function visiblize(){
        toggleButton.setVisible(true);
    }
    function invisiblize(){
        toggleButton.setVisible(false);
    }
    function isVisible(){
        return toggleButton.visible;
    }
    function destroy() {
        toggleButton.destroy();
    }
    function getPosition(){
        return {
            x: toggleButton.x, 
            y: toggleButton.y, 
            z: toggleButton.depth
        }
    }
    function setPosition(x, y){
        toggleButton.setPosition(x, y);
    }
    function setDepth(z) {
        toggleButton.setDepth(z);
    }
    function getDimensions(){
        return {
            width: toggleButton.width * scale,
            height: toggleButton.height * scale
        }
    }

    return {
        buttonId: buttonId,
        disableButton: disableButton,
        enableButton: enableButton,
        visiblize: visiblize,
        invisiblize: invisiblize,
        isVisible: isVisible,
        getPosition: getPosition,
        setPosition: setPosition,
        setDepth: setDepth,
        getDimensions: getDimensions,
        destroy: destroy,
    }

}

export default ToggleButton;
