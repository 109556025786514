import GenericButton from './GenericButton'


/**
 *  This deck doesn't contain any information about the contents of the deck outside of simply
 *  managing the appearance of the deck on screen with respect to the number of cards in it.
 */
const VisualDeck = (deckId, scene, artName, startingDeckSize, scale, position, onClick) => {
    
    var deckObjectsArray = [];
    const CARD_OFFSET_SMALL = 4;
    const CARD_OFFSET_LARGE = 8;

    var lastKnownDeckSize = startingDeckSize;
    

    function destroy() {
        deckObjectsArray = null;
    };

    /*
        draws the deck stack onto the screen.
    */
    function draw(){
        var object1 = GenericButton(
                deckId, 
                scene, 
                { artName: artName }, 
                scale, 
                { x: position.x + (CARD_OFFSET_SMALL), y: position.y + (CARD_OFFSET_SMALL), z: position.z }, 
                onClick);
        object1.makeNonInteractive();

        var object2 = GenericButton(
                deckId, 
                scene, 
                { artName: artName }, 
                scale, 
                { x: position.x + (CARD_OFFSET_SMALL*2), y: position.y + (CARD_OFFSET_SMALL*2), z: position.z }, 
                onClick);
        object2.makeNonInteractive();

        var object3 = GenericButton(
                deckId, 
                scene, 
                { artName: artName }, 
                scale, 
                { x: position.x + (CARD_OFFSET_SMALL*3), y: position.y + (CARD_OFFSET_SMALL*3), z: position.z }, 
                onClick);
        object3.makeNonInteractive();

        var object4 = GenericButton(
                deckId, 
                scene, 
                { artName: artName }, 
                scale, 
                { x: position.x + (CARD_OFFSET_SMALL*4), y: position.y + (CARD_OFFSET_SMALL*4), z: position.z }, 
                onClick);
        object4.makeNonInteractive();

        var object5 = GenericButton(
                deckId, 
                scene, 
                { artName: artName }, 
                scale, 
                { x: position.x + (CARD_OFFSET_SMALL*5), y: position.y + (CARD_OFFSET_SMALL*5), z: position.z }, 
                onClick);
        object5.makeNonInteractive();

        deckObjectsArray.push(object1);
        deckObjectsArray.push(object2);
        deckObjectsArray.push(object3);
        deckObjectsArray.push(object4);
        deckObjectsArray.push(object5);
    };

    function redrawDeck(){
        if(lastKnownDeckSize > 3){
            deckObjectsArray[0].setPosition(position.x + (CARD_OFFSET_SMALL*1), position.y + (CARD_OFFSET_SMALL*1));
            deckObjectsArray[1].setPosition(position.x + (CARD_OFFSET_SMALL*2), position.y + (CARD_OFFSET_SMALL*2));
            deckObjectsArray[2].setPosition(position.x + (CARD_OFFSET_SMALL*3), position.y + (CARD_OFFSET_SMALL*3));
            deckObjectsArray[3].setPosition(position.x + (CARD_OFFSET_SMALL*4), position.y + (CARD_OFFSET_SMALL*4));
            deckObjectsArray[4].setPosition(position.x + (CARD_OFFSET_SMALL*5), position.y + (CARD_OFFSET_SMALL*5));

            deckObjectsArray[0].visiblize();
            deckObjectsArray[1].visiblize();
            deckObjectsArray[2].visiblize();
            deckObjectsArray[3].visiblize();
            deckObjectsArray[4].visiblize();

        }
        else if(lastKnownDeckSize === 3){
            deckObjectsArray[0].setPosition(position.x + (CARD_OFFSET_LARGE*1), position.y + (CARD_OFFSET_LARGE*1));
            deckObjectsArray[1].setPosition(position.x + (CARD_OFFSET_LARGE*2), position.y + (CARD_OFFSET_LARGE*2));
            deckObjectsArray[2].setPosition(position.x + (CARD_OFFSET_LARGE*3), position.y + (CARD_OFFSET_LARGE*3));

            deckObjectsArray[0].visiblize();
            deckObjectsArray[1].visiblize();
            deckObjectsArray[2].visiblize();
            deckObjectsArray[3].invisiblize();
            deckObjectsArray[4].invisiblize();
        }
        else if(lastKnownDeckSize === 2){
            deckObjectsArray[0].setPosition(position.x + (CARD_OFFSET_LARGE*1), position.y + (CARD_OFFSET_LARGE*1));
            deckObjectsArray[1].setPosition(position.x + (CARD_OFFSET_LARGE*2), position.y + (CARD_OFFSET_LARGE*2));
            deckObjectsArray[2].setPosition(position.x + (CARD_OFFSET_LARGE*3), position.y + (CARD_OFFSET_LARGE*3));
            
            deckObjectsArray[0].visiblize();
            deckObjectsArray[1].visiblize();
            deckObjectsArray[2].invisiblize();
            deckObjectsArray[3].invisiblize();
            deckObjectsArray[4].invisiblize();
        }
        else if(lastKnownDeckSize === 1){
            deckObjectsArray[0].setPosition(position.x + (CARD_OFFSET_LARGE*1), position.y + (CARD_OFFSET_LARGE*1));
            deckObjectsArray[1].setPosition(position.x + (CARD_OFFSET_LARGE*2), position.y + (CARD_OFFSET_LARGE*2));
            deckObjectsArray[2].setPosition(position.x + (CARD_OFFSET_LARGE*3), position.y + (CARD_OFFSET_LARGE*3));
            
            deckObjectsArray[0].visiblize();
            deckObjectsArray[1].invisiblize();
            deckObjectsArray[2].invisiblize();
            deckObjectsArray[3].invisiblize();
            deckObjectsArray[4].invisiblize();
        }
        else if(lastKnownDeckSize === 0){
            deckObjectsArray[0].setPosition(position.x + (CARD_OFFSET_LARGE*1), position.y + (CARD_OFFSET_LARGE*1));
            deckObjectsArray[1].setPosition(position.x + (CARD_OFFSET_LARGE*2), position.y + (CARD_OFFSET_LARGE*2));
            deckObjectsArray[2].setPosition(position.x + (CARD_OFFSET_LARGE*3), position.y + (CARD_OFFSET_LARGE*3));
            
            deckObjectsArray[0].invisiblize();
            deckObjectsArray[1].invisiblize();
            deckObjectsArray[2].invisiblize();
            deckObjectsArray[3].invisiblize();
            deckObjectsArray[4].invisiblize();
        }
    }

    function visuallyUpdateDeckSize(updatedDeckSize){
        if (lastKnownDeckSize === updatedDeckSize){
            return; //do nothing, no change
        } else {
            lastKnownDeckSize = updatedDeckSize;
            redrawDeck();
        }
    }

    function getLastKnownDeckSize(){
        return lastKnownDeckSize;
    }

    return {
        draw: draw,
        visuallyUpdateDeckSize: visuallyUpdateDeckSize,
        getLastKnownDeckSize: getLastKnownDeckSize,
        destroy: destroy
    }

}

export default VisualDeck;
