import React from 'react'
import { useHistory, Link } from "react-router-dom";
import { Grid, Typography, makeStyles, Card, CardActionArea, CardContent, CardMedia} from '@material-ui/core';
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'
import WatchedElement from '../components/WatchedElement';
import { useStore } from '../helpers/GlobalStore';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import ArtLoader from "../assets/CardArtProvider"
import { Star } from '../assets/images/star';
import StarField from '../components/star-field/StarField';
import BackgroundManager from '../components/BackgroundImageManager';
import { useWindowDimensions } from '../helpers/windowDimentionProvider';
import styled, {keyframes} from 'styled-components';
import { Headline } from '../components/Headline';
import { Timeline } from './timeline/StyledTimeline';
import { SocialIcon } from 'react-social-icons';

const { getArt } = ArtLoader();

// const nebulaGlow = keyframes`

//     from { 
//         filter: hue-rotate(0deg);
//     }

//     to { 
//         filter: hue-rotate(-60deg);
//     }
// `


const fallingRain = keyframes`

    0% { 
        background-position: 0% 0%;
    }

    100% { 
        background-position: -15% 100%;
    }
`



const StyledHome = styled.div`

    height: 100vh;
    clip-path: ellipse(45% 40% at 50% 50%);
    background-image: ${props => props.img? `url(${props.img})`: 'url(start.webp)'} ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: -1;
    scal: 1.5;

`

const RainFx = styled.div`
    postion: absolute;
    top: 0;
    left: 0;

    height: 100vh;
    background: url(rain.png);
    animation-name: ${fallingRain};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    clip-path: ellipse(45% 40% at 50% 50%);
    z-index: -1;
    &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
`


/* Part of StyledHome
    animation-name: ${nebulaGlow};
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    will-change: auto;
*/

  const InfoCard = styled.div`

    width: 320px;
    color: white;
    text-align: left;
    font-size: 14pt;
    justify-content: center;
    align-items: center;
    height: 20vh;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    will-change: auto;
  
    &::before{
        border-radius: 16px;
        width: 320px;
        height: 20vh;
        content: "";
        background-image: ${props => props.imageUrl? `url(${props.imageUrl})`:'none'};
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: -1;
        opacity: .35;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

  `


const getCardScale = (width) => {
    
    if(width > 1920){
        return 1;
    }
    else if(width > 1200){
        return 1;
    }
    else if(width > 800){
        return 0.8;
    }
    else if(width > 600){
        return 0.7;
    }
    else if(width > 300){
        return 0.6;
    }
    else{
        return 1;
    }
}
  

const image1 = getArt('HowHomeBg')
const battleToWin = getArt('BattleToWin')
const image3 =
"https://brightcove04pmdo-a.akamaihd.net/5104226627001/5104226627001_5297440765001_5280261645001-vs.jpg?pubId=5104226627001&videoId=5280261645001";
const image4 =
"https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/empire-state-building-black-and-white-square-format-john-farnan.jpg";



const ParaBanner = ({img}) => {

    const background = {
        children: (
            <StyledHome img={img}/>
        )
        ,

        scale: [1,1,'linear'],
        opacity: [.6, 0, [.56,0,0,1.4]],
        shouldAlwaysCompleteAnimation: true
      };

      const rain = {
        children: (
            <RainFx/>
        )
        ,

        scale: [1,1,'linear'],
        opacity: [.6, 0, [.56,0,0,1.4]],
        shouldAlwaysCompleteAnimation: true
      };

    const headline = {
        translateY: ['-10vh', '-20vh', [.56,-0.01,0,2]],
        opacity: [1, 0, [.56,-0.01,0,1.3]],
        
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
        <div className="inset center">
            <Headline fontSize='6vw'>Pepe vs Pepe<br/> PvP</Headline>
        </div>
        )
    };

    // const dexLinks = {
    //     translateY: 65,
    //     opacity: [1, 0, [.8,-0.01,0,1.3]],
        
    //     shouldAlwaysCompleteAnimation: true,
    //     expanded: false,
    //     children: (
    //         <DexLinks/>
    //     )
    // }

    const subtitle = {
        translateY: ['20vh', '-10vh', [.56,-0.01,0,2]],
        opacity: [1, 0, [.56,-0.01,0,1.3]],
        
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
        <div className="inset center">
            <div className='smallHeadline' style={{fontSize:'18pt', fontWeight: 600, padding: '20px', filter:'drop-shadow(0px 0px 4px black)'}}>
                WHO WILL CONTROL THE SWAMP?
            </div>
        </div>
        )
    };

 
    // const starsy = {
    //     translateY: ['0vh', '0vh', [.56,-0.01,0,2]],
    //     opacity: [1, 0, [.56,-0.01,0,1.2]],
    //     scale: [1, 5, [.7,-0.01,0,1.4]],
    //     shouldAlwaysCompleteAnimation: true,
    //     expanded: false,    
    //     children: (
    //     <div className="inset center">
    //         <StarField/>
    //     </div>
    //     )
    // };
        
    return (
        <ParallaxBanner
        layers={[ background, rain, headline, subtitle]}
        style={{width:'100vw', height:'100vh'}}
        />
    );
};


const GameToEarn = ({width}) => {

    const background = {
        children: (
            <div 
                style={{  
                    alignItems:'center', 
                    verticalAlign:'middle', 
                    justifyContent:'center', 
                    width: '100vw', height: '100vh', 
                    margin: 0, padding: 0,
                    opacity: 0.6, 
                    background: `url(${getArt('Bg2')})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'
                }}
            />
        )
        ,

        scale: [ 1, 1.2],
        opacity: [.4, 1, [.56,0,0,1.4]],
        shouldAlwaysCompleteAnimation: true,
        expanded: true
      };

      
    const foreground = {
        translateY: ['40vh', '-50vh'],       
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
            <Grid container  justifyContent={'center'}  spacing={2} style={{width:'100%', height:'90vh', verticalAlign:'center', marginBottom:'0px', padding:'auto', alignItems:'center'}}>
                <Grid item style={{width:'100%', textAlign:'center'}} >
                    <div className='smallHeadline' style={{fontWeight:'bold', filter:'drop-shadow(0px 0px 2px rgba(0, 0, 0, 1))'}}>
                        Game to Earn
                    </div>
                </Grid>

                <Grid
                    item
                    style={{
                        margin: 'auto', 
                        textAlign: 'center', 
                        verticalAlign: 'middle', 
                        justifyContent: 'center', 
                        padding:' 4%', width: width > 750? '500px': '92%', 
                        backgroundColor: 'rgba(92, 158, 139, .5)', 
                        borderRadius: '50%',
                        filter: 'drop-shadow(0 20px 10px rgba(0, 0, 0, .8))'
                    }}
                >
                    <div style={{textAlign:'left', fontFamily:'Roboto',  fontSize:'18pt', fontWeight: 600, display:'inline-block', padding:'20px'}}>
                        Play matches against other holders to earn more $PvP.
                    </div>
                </Grid>

                <Grid
                    item 
                    style={{margin:'auto', padding:'auto', justifyContent:'center', alignItems:'center', verticalAlign:'middle'}}
                >
                    <img src={getArt('GameToEarn')} style={{ height:'auto', maxWidth: width >750?'35vw':'80vw', alignItems:'center', verticalAlign:'middle', marginLeft:width > 750? '20px' : 'auto', filter:'drop-shadow(0 0 1px yellow)'}}/>
                </Grid>

            </Grid>
        )
    };
        
    return (
        <ParallaxBanner
        layers={[ background, foreground]}
        style={{width:'100vw', height:'100vh'}}
        />
    );
};

const UpgradeNfts = ({width}) => {

    const background = {
        children: (
            <div 
                style={{  
                    alignItems:'center', 
                    verticalAlign:'middle', 
                    justifyContent:'center', 
                    width: '100vw', height: '100vh', 
                    margin: 0, padding: 0,
                    opacity: 0.6, 
                    background: `url(${getArt('Bg3')})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'
                }}
            />
        )
        ,

        scale: [ 1, 1.2],
        opacity: [.4, 1, [.56,0,0,1.4]],
        shouldAlwaysCompleteAnimation: true,
        expanded: true
      };

      
    const foreground = {
        translateY: ['40vh', '-50vh'],        
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
            <Grid container  justifyContent={'center'}  spacing={2} style={{width:'100%', height:'90vh', margin: '0px', verticalAlign:'center', padding:'auto', alignItems:'center'}}>
                <Grid item style={{width:'100%', textAlign:'center'}} >
                    <div className='smallHeadline' style={{fontWeight:'bold', filter:'drop-shadow(0px 0px 2px rgba(0, 0, 0, 1))'}}>
                        Build Custom Decks
                    </div>
                </Grid>
        
                <Grid
                    item 
                    style={{margin:'auto', padding:'auto', justifyContent:'center', alignItems:'center', verticalAlign:'middle'}}
                >
                    <img src={getArt('Upgrade')} style={{ height:'auto', maxWidth:'800px', width: width >750?'35vw':'80vw', alignItems:'center', verticalAlign:'middle', marginLeft:width > 750? '20px' : 'auto'}}/>
                </Grid>

                <Grid
                    item
                    style={{
                        margin: 'auto', 
                        padding:'4%', width: width > 750? '500px': '92%', 
                        backgroundColor: 'rgba(92, 158, 139, .5)', 
                        borderRadius: '50%',
                        filter: 'drop-shadow(0 20px 10px rgba(0, 0, 0, .8))',
                        textAlign: 'center'
                    }}
                >
                    <div style={{textAlign:'left', fontFamily:'Roboto',  fontSize:'18pt', fontWeight: 600, padding:'20px', display:'inline-block'}}>
                       Select your team of 15 Pepes and Wojaks to defeat your opponents
                    </div>
                </Grid>
            </Grid>
        )
    };
        
    return (
        <ParallaxBanner
        layers={[ background, foreground]}
        style={{width:'100vw', height:'100vh'}}
        />
    );
};

const StakeNfts = ({width}) => {

    const background = {
        children: (
            <div 
                style={{  
                    alignItems:'center', 
                    verticalAlign:'middle', 
                    justifyContent:'center', 
                    width: '100vw', height: '80vh', 
                    margin: 0, padding: 0, 
                    background: `url(${getArt('World45')})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'
                }}
            />
        )
        ,

        scale: [ 1, 1.2],
        opacity: [.4, 1, [.56,0,0,1.4]],
        shouldAlwaysCompleteAnimation: true,
        expanded: true
      };

      
    const foreground = {
        translateY: ['40vh', '-50vh'],        
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
            <Grid container  justifyContent={'center'}  spacing={2} style={{width:'100%', height:'90vh', marginBottom:'0px', verticalAlign:'center', padding:'auto', alignItems:'center'}}>
                <Grid item style={{width:'100%', textAlign:'center'}} >
                    <div className='smallHeadline' style={{filter:'drop-shadow(0px 0px 2px rgba(255, 255, 255, 1))'}}>
                        Stake NFTs
                    </div>
                </Grid>
    
                <Grid
                    item
                    style={{margin:'auto', textAlign:'center', verticalAlign:'middle', justifyContent:'center', backgroundColor:'rgba(255,255,255,.8)', padding:'4%', width: width > 750? 'auto': '92%'}}
    
                >
                    <Typography style={{textAlign:'left', fontFamily:'Roboto',  fontSize:'16pt'}}>
                        Stake your Pepes to unlock exclusive new Pepes! <br/>
                    </Typography>
                </Grid>

                <Grid
                    item 
                    style={{margin:'auto', padding:'auto', justifyContent:'center', alignItems:'center', verticalAlign:'middle'}}
                >
                    <img src={getArt('Stake')} style={{backgroundSize:'contain', backgroundRepeat:'no-repeat', height:'auto', maxWidth: width >750?'35vw':'100vw', alignItems:'center', verticalAlign:'middle', marginLeft:width > 750? '20px' : 'auto'}}/>
                </Grid>
        
            </Grid>
        )
    };
        
    return (
        <ParallaxBanner
        layers={[ background, foreground]}
        style={{width:'100vw', height:'100vh'}}
        />
    );
};

const Tournaments = ({width}) => {

    const background = {
        children: (
            <div 
                style={{  
                    alignItems:'center', 
                    verticalAlign:'middle', 
                    justifyContent:'center', 
                    width: '100vw', height: '100vh', 
                    margin: 0, padding: 0,
                    opacity: 0.6,
                    background: `url(${getArt('Bg4')})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'
                }}
            />
        )
        ,

        scale: [ 1, 1.2],
        opacity: [.4, 1, [.56,0,0,1.4]],
        shouldAlwaysCompleteAnimation: true,
        expanded: true
      };

      
    const foreground = {
        translateY: ['40vh', '-50vh'],        
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
            <Grid container  justifyContent={'center'}  spacing={2} style={{width:'100%', height:'90vh', verticalAlign:'center', marginBottom:'0px', marginTop:'0px', padding:'auto', alignItems:'center'}}>
                <Grid item style={{width:'100%', textAlign:'center'}} >
                    <div className='smallHeadline' style={{ fontWeight:'bold', filter:'drop-shadow(0px 0px 2px rgba(0, 0, 0, 1))'}}>
                        Compete
                    </div>
                </Grid>

                <Grid
                    item
                    style={{
                        margin: 'auto', 
                        textAlign: 'center', 
                        verticalAlign: 'middle', 
                        justifyContent: 'center', 
                        padding:' 4%', width: width > 750? '500px': '92%', 
                        backgroundColor: 'rgba(92, 158, 139, .5)', 
                        borderRadius: '50%',
                        filter: 'drop-shadow(0 20px 10px rgba(0, 0, 0, .8))'
                    }}

                >
                    <div style={{textAlign:'left', fontFamily:'Roboto',  fontSize:'18pt', fontWeight: 600, padding:'20px', display:'inline-block'}}>
                        Compete in massive tournaments to win huge $PvP prizes
                        And unique tournmament only NFTs!
                    </div>
                </Grid>

                <Grid
                    item 
                    style={{
                        margin:'auto', padding:' 4%', 
                        justifyContent:'center', alignItems:'center', verticalAlign:'middle',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(92, 158, 139, .5)', 
                        filter: 'drop-shadow(0 20px 10px rgba(0, 0, 0, .8))'
                    }}
                >
                    <img 
                        src={getArt('Tournament')} 
                        style={{
                            backgroundSize:'contain', 
                            backgroundRepeat:'no-repeat', 
                            height:'auto', maxWidth: width >750?'35vw':'100vw', 
                            alignItems:'center', verticalAlign:'middle', 
                            marginLeft:width > 750? '20px' : 'auto',
                        }}
                    />
                </Grid>

            </Grid>
        )
    };
        
    return (
        <ParallaxBanner
        layers={[ background, foreground]}
        style={{width:'100vw', height:'100vh'}}
        />
    );
};

const DexLinks = () => {
    return(

        <Grid container  justifyContent={'center'}   style={{width:'100%', height:'80px', verticalAlign:'center'}}>

            <Grid
                item 
                style={{textAlign:'center', verticalAlign:'middle', justifyContent:'center'}}
            >
                <a
                    href='https://www.google.com'
                    target="_blank" 
                    rel="noreferrer noopener"
                >
                    <img
                        src='CoinMarketCap-logo.png'
                        alt="Coin Market Cap" 
                        className='coinListingLinks'
                    />
                </a>
            </Grid>

            <Grid
                item 
                style={{textAlign:'center', verticalAlign:'middle', justifyContent:'center'}}
            >
                <a
                    href='https://www.google.com'
                    target="_blank" 
                    rel="noreferrer noopener"
                >
                    <img
                        src='bkek.png'
                        alt="BKEK" 
                        className='coinListingLinks'
                    />
                </a>
            </Grid>

            <Grid
                item 
                style={{textAlign:'center', verticalAlign:'middle', justifyContent:'center'}}
            >
                <a
                    href='https://www.google.com'
                    target="_blank" 
                    rel="noreferrer noopener"
                >
                    <img
                        src='CoinGecko-logo.png'
                        alt="Coin Gecko" 
                        className='coinListingLinks'
                    />
                </a>
            </Grid>
            <Grid 
                item
                style={{textAlign:'center', verticalAlign:'middle', justifyContent:'center'}}
            >
                <a
                    href='https://www.google.com'
                    target="_blank" 
                    rel="noreferrer noopener"
                >
                    <img
                        src='DEXTools-logo.png'
                        alt="Dex Tools" 
                        className='coinListingLinks'
                    />
                </a>
            </Grid>
        </Grid>
    )
}

const CoinDetails = ({width}) => {

    const background = {
        children: (
            <div 
                style={{  
                    alignItems:'center', 
                    verticalAlign:'middle', 
                    justifyContent:'center', 
                    width: '100vw', height: '100vh', 
                    margin: 0, padding: 0,
                    opacity: 0.5, 
                    background: `url(${getArt('Bg1')})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'
                }}
            />
        )
        ,

        scale: [ 1, 1.2],
        opacity: [.4, 1, [.56,0,0,1.4]],
        shouldAlwaysCompleteAnimation: true,
        expanded: true
      };

      
    const foreground = {
        translateY: ['40vh', '-50vh'],        
        shouldAlwaysCompleteAnimation: true,
        expanded: false,    
        children: (
            <Grid container  justifyContent={'center'} spacing={4}  style={{width:'100%', height:'100%', margin:'0px', verticalAlign:'center', marginBottom:'150px', padding:'auto', alignItems:'center'}}>

                <Grid item style={{width:'100%', textAlign:'center'}} >
                    <div className='smallHeadline' style={{ fontWeight:'bold', filter:'drop-shadow(0px 0px 2px rgba(0, 0, 0, 1))'}}>
                        Tokenomics
                    </div>
                </Grid>
                <Grid
                    item 
                    style={{textAlign:'center', verticalAlign:'middle', justifyContent:'center'}}
                >
                    <Fade left style={{justifyContent:'center', alignItems:'center'}}>
                        <Card 
                            centered
                            elevation={0}  
                            className='cardAreaBlue' 
                            style={{  alignContent:'center', border:'none', outline:'none' }}>
                            <CardActionArea  style={{height: 250, width: 300, background: 'none', margin:'auto', padding:'auto'}}>
                                <CardMedia
                                    component="img"
                                    style={{ height:'100px', width:'90px', justifyContent:'center', alignItems:'center', textAlign:'center', margin:'auto', marginTop: 10}}
                                    image={getArt('Safe')}
                                    alt="secure icon"
                                />
                                <CardContent className='cardHoverBlue'>
                                    <div style={{fontFamily:'Roboto', fontSize:'24px'}}>
                                        Safe
                                    </div>
                                    <div style={{fontFamily:'Roboto', fontSize:'16px'}}>
                                        Initial liquidity provided by team. <br/>
                                        Liquidity locked, contract verified and renounced.  
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Fade>
                </Grid>

                <Grid
                    item 
                    style={{textAlign:'center', verticalAlign:'middle', justifyContent:'center'}}
                >
                    <Fade bottom style={{justifyContent:'center', alignItems:'center'}}>
                        <Card
                            elevation={0}   
                            centered  
                            className='cardAreaGreen'
                            style={{  alignContent:'center', border:'none', outline:'none' }}>
                            <CardActionArea  style={{height: 250, width: 300, background: 'none', margin:'auto', padding:'auto'}}>
                                <CardMedia
                                component="img"
                                style={{ height:'100px', width:'100px', justifyContent:'center', alignItems:'center', textAlign:'center', margin:'auto'}}
                                image={getArt('Ecosystem')}
                                alt="ecosystem icon"
                                />
                                <CardContent className='cardHoverGreen'>
                                    <div style={{fontFamily:'Roboto', fontSize:'24px'}}>
                                        Ecosystem
                                    </div>
                                    <div style={{fontFamily:'Roboto', fontSize:'16px'}}>
                                        PvP Ecosystem is a self-sustaining, holder-oriented system. <br/>
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Fade>
                </Grid>

                <Grid
                    item 
                    style={{textAlign:'center', verticalAlign:'middle', justifyContent:'center'}}
                >

                    <Fade right style={{justifyContent:'center', alignItems:'center'}}>
                        <Card
                            elevation={0} 
                            centered  
                            className='cardAreaGold' 
                            style={{  alignContent:'center', border:'none', outline:'none' }}>
                            <CardActionArea  style={{height: 250, width: 300, background: 'none', margin:'auto', padding:'auto'}}>
                                <CardMedia
                                component="img"
                                style={{ height:'100px', width:'100px', justifyContent:'center', alignItems:'center', textAlign:'center', margin:'auto'}}
                                image={getArt('Tax')}
                                alt="tax free icon"
                                />
                                <CardContent className='cardHoverGold'>
                                    <div style={{fontFamily:'Roboto', fontSize:'24px'}}>
                                        Low Tax
                                    </div>
                                    <div style={{fontFamily:'Roboto', fontSize:'16px'}}>
                                        Tax funds will be used for Game and Tournament rewards.
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Fade>
                </Grid>
            </Grid>
        )
    };
        
    return (
        <ParallaxBanner
        layers={[ background, foreground]}
        style={{width:'100vw', height:'100vh'}}
        />
    );
};



const HowHomePage = () => {
    const {width, height} = useWindowDimensions()

    return(
        <div style={{width:'100vw'}}>
            <ParaBanner img={width > 1000? 'start.webp':'start-mobile.webp'}/>

            <Typography 
                variant='h4'
                className='smallHeadline' 
                style={{
                    width:'65%', 
                    alignItems:'center', verticalAlign:'middle', justifyContent:'center', 
                    margin: 'auto', marginBottom:'70px',
                    fontFamily:'lores-9-wide',
                }}
            >
                Welcome to the Swamp! 
                Pepe vs Pepe is a free to play card game in which you can collect 
                and build your deck of Pepes and Wojaks.
            </Typography>

            <div style={{background:'rgba(150,50,50,.3)', width:'65%', border: '1px solid rgba(255,0,0,1)', borderRadius:'16px', margin:'auto', padding:'auto', marginBottom:'25px'}}>

                <Typography 
                    variant='h4'
                    className='smallHeadline' 
                    style={{
                        width:'65%', 
                        alignItems:'center', verticalAlign:'middle', justifyContent:'center', 
                        margin: 'auto',
                        padding:'25px',
                        fontFamily:'lores-9-wide',
                    }}
                >
                    Contract Address Coming Soon!
                </Typography>
            </div>

            <CoinDetails />

            <GameToEarn width={width}/>

            <UpgradeNfts width={width}/>

            {/* <StakeNfts width={width}/> */}

            <Tournaments width={width}/>

    {/*
             <Parallax
                speed={15}
                
                translateX={width > 600? ['0vw', `${40 * getCardScale(width)}vw` ]:['0vw', '15vw' ] } 
                scale={[getCardScale(width), getCardScale(width) * 1.2]}
                opacity={[0, 1,  [0,1,1,1]]}
                shouldAlwaysCompleteAnimation={true}
                style={{width:'320px'}}
            >
                <InfoCard imageUrl='build-decks.webp'>
                    <Headline> Build Squads</Headline>
                    <p style={{  textAlign:'justify', margin:'2%',  flexDirection:'row'}}>
                        Build powerful decks with unique Hounds to defeat your enemies
                    </p>
                </InfoCard>
            </Parallax>
            <div style={{height:'50px', width:'100%'}}  />
            <Parallax
                speed={15}
                translateX={ width > 600? [ `${(60 *getCardScale(width))}vw`, `${(20 *getCardScale(width))}vw`, [0,.3,1,1*getCardScale(width)]]:['15vw', '0vw' ] }
                scale={[getCardScale(width), getCardScale(width) * 1.2]}
                opacity={[.15, 1, [0,1,1,1]]}
                shouldAlwaysCompleteAnimation={true}
                style={{width:'320px'}}
            >
                <InfoCard imageUrl='win-to-earn.webp' >
                    <Headline> Play to Earn</Headline>
                    <p style={{  textAlign:'justify', margin:'2%',  flexDirection:'row'}}>
                        Play matches using cHoW tokens to win more cHoW.
                        Claim cHoW  winnings as $HoW or cHoW. 
                        Buy more cards with cHoW or $HoW!
                    </p>
                </InfoCard>
            </Parallax>
            <div style={{height:'50px', width:'100%'}}  />
            <Parallax 
                speed={15}
                translateX={width > 600? [ `${(15 *getCardScale(width))}vw`, `${(60 *getCardScale(width))}vw`]:['0vw', '15vw' ]}
                scale={[getCardScale(width), getCardScale(width) * 1.2]}
                opacity={[.25, 1, [0,1,1,1]]}
                shouldAlwaysCompleteAnimation={true}
                style={{width:'320px'}}
            >
                <InfoCard imageUrl='compete.webp' >
                    <Headline> Compete</Headline>
                    <p style={{  textAlign:'justify', margin:'2%',  flexDirection:'row'}}>
                        Compete in regular tournaments for big cHoW winnings.
                        Earn exclusive tournament NFTs
                    </p>
                </InfoCard>
            </Parallax>
            <div style={{height:'50px', width:'100%'}}  />
            <Parallax
                speed={15}
                translateX={width > 600? [ `${(60 *getCardScale(width))}vw`, `${(20 *getCardScale(width))}vw`, [0,.3,1,1*getCardScale(width)]]:['15vw', '0vw' ]}
                scale={[getCardScale(width), getCardScale(width) * 1.2]}
                opacity={[.15, 1, [0,1,1,1]]}
                shouldAlwaysCompleteAnimation={true}
                style={{width:'320px'}}
            >
                <InfoCard imageUrl='win-to-earn.webp' >
                    <Headline> Name Them</Headline>

                    <p style={{  textAlign:'justify', margin:'2%',  flexDirection:'row'}}>
                        Give your uniquely minted Hound it's name! 
                        Only the first person to own this hound can name it!
                    </p>
                </InfoCard>
            </Parallax> 
        */}

            <div style={{height:'100px', width:'100%'}}  />

            <Parallax
            speed={10}
                scale={[getCardScale(width), getCardScale(width) * 1.4]}
                opacity={[.15, 1, [0,1,1,1]]}
                shouldAlwaysCompleteAnimation={true}
            >
                <Headline> Timeline</Headline>
            </Parallax>
            <div style={{height:'100px', width:'100%'}}  />
            <Parallax
                scale={[getCardScale(width), getCardScale(width) * 1.4]}
                
                shouldAlwaysCompleteAnimation={true}
                style={{width:'100%'}}
            >
                <Timeline/>
            </Parallax>
            <div style={{height:'50px', width:'100%'}}  />

            <Grid
                className='footerStyles' 
                container 
                id="bottom-row"
                style={{ justifyContent: 'center', alignItems: 'center' }}
            >
            <Grid item style={{ marginRight: "15px", justifyContent: 'center', alignItems: 'center' }}>
                <SocialIcon url='https://t.me/pvpportal' target="_blank" rel="noopener noreferrer" className="socialIcon" network='telegram' />
            </Grid>
            <Grid item style={{ justifyContent: 'center', alignItems: 'center' }}>
                <SocialIcon url='https://twitter.com/PepesvsPepes' target="_blank" rel="noopener noreferrer" className="socialIcon" network='twitter'/>
            </Grid>
        </Grid>
            
            
        </div>
    )
}
export default HowHomePage;
