import React, { createContext, useContext, useEffect } from "react";
import axios from 'axios';
import CompendiumUtil from "../pages/compendium/CompendiumUtil";
import CONFIG from "../config/config.js"

import { useStore } from "./GlobalStore";
import { signAuthenticationMessage } from "./web3ReactUtil";
import HashMap from "hashmap";
import { useWeb3React } from "@web3-react/core";
import { useAuthReducer } from "./AuthReducer";

export const AoeApiContext = createContext();


const CHOW_BUNDLES = [
    {
        bundleId: 0,
        displayName: '10 Chow Bundle',
        bundleName: 'Chow-Bundle-10',
        basePrice: 10,
        salePrice: undefined,
        chowInBundle: 10
    },
    {
        bundleId: 1,
        displayName: '50 Chow Bundle',
        bundleName: 'Chow-Bundle-50',
        basePrice: 50,
        salePrice: undefined,
        chowInBundle: 50
    },
    {
        bundleId: 2,
        displayName: '100 Chow Bundle',
        bundleName: 'Chow-Bundle-100',
        basePrice: 100,
        salePrice: undefined,
        chowInBundle: 100
    },
    {
        bundleId: 3,
        displayName: '200 Chow Bundle',
        bundleName: 'Chow-Bundle-200',
        basePrice: 200,
        salePrice: undefined,
        chowInBundle: 200
    },
]



export const AoeApiProvider = ({children}) => {

    var compendiumUtil = CompendiumUtil();

    const { setUserRoles,
            setUserAddress,
            setUserName,
            setConnectionText,
            setConnectionErrorMsg,
            setUserRank,
            setBaseCards,
            setHasPacks,
            ownedPacks,
            decks,
            validDecks,
            deckCardCounts,
            setWebToken,
            compendium, setCompendium,
            setChildUpdateNeeded,
            walletType,
            setWalletType,
            setUserAuthExists,
            setLootRewards,
            packToSectionMap,
            setCurrentChainId,
            purchasableCardPacks, 
            setPurchasableCardPacks,
            packNameDBToUINameMap,
            chowOwned,
            setChowOwned,
            howOwned,
            setHowOwned,
            earnedChow,
            setEarnedChow
    } = useStore();

    const {authState, dispatchAuthUpdate} = useAuthReducer()

    const {setError} = useWeb3React()


    const setHeaders = () => {
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = CONFIG.CORS_ORIGIN
        axios.defaults.withCredentials = true
    }
    
    const getUserByAddress = async (address) => {
        setHeaders()
        try{
            await axios.get(`${CONFIG.API_ENDPOINT}/users?publicAddress=${address}`).then((response) => {
                setUserName(response.data.userName)
                setUserRank(response.data.rankingScore)
            })
        }
        catch (err){
           console.error(err)
        }
    }



    // Asynchronously sync the backend wallet token contents to match the blockchain
    const asyncSyncWalletTokens = async (completionCallback, errorCallback) => {
        setHeaders()

        try{
            await axios.put(`${CONFIG.API_ENDPOINT}/syncwallet/tokens`).then((resp) => {
                completionCallback && completionCallback(resp)
            })
        }
        catch (err){
            console.error(err)
            errorCallback && errorCallback(err)
        }
    }

    const setSuccesfulSigninState = (response) =>{
        
        const localStorage = window.localStorage;
        const address = response.data.publicAddress;
        setUserAuthExists(true)
        localStorage.setItem('currentUser', address)
        Promise.resolve(getUserRole(address)) 
        setUserName(response.data.userName)
        setWebToken(response.data.jwt)
        setUserAddress(address)
        dispatchAuthUpdate(
            {
                type: 'updateAuth',
                payload: {
                    userAuthorized: true,
                    authToken: response.data.jwt,
                    userAddress: address,
                    chainId: response.data.chainId,
                    authChecked: true,
                    userName: response.data.userName,
                    userRank: response.data.rankingScore
                }
            }
        );
        const abbreviatedUserAddress = address.substring(0,6) + "..." + address.substring(address.length - 4, address.length)
        setConnectionText(abbreviatedUserAddress)
        setConnectionErrorMsg(undefined)
        setCurrentChainId(response.data.chainId)
        setUserRank(response.data.rankingScore)
        setUserName(response.data.userName)
        setWalletType(response.data.lastLoginWalletType)
    }

    const signIn = async (address, signer) => {
        setHeaders()
        setUserAuthExists(false)
        try{
            await axios.put(`${CONFIG.API_ENDPOINT}/users?publicAddress=${address}`)
            .then(user => {
                try{
                    handleSignMessage(address, user.data.nonce, signer)
                    .then(async signature => {
                        const loginRequest = {
                            publicAddress: address,
                            signature: signature,
                            walletType: walletType,
                            chainId: signer.provider.provider.networkVersion
                        }
            
                        try{
    
                            await axios.put(`${CONFIG.API_ENDPOINT}/users/authenticate?publicAddress=${address}`, loginRequest)
                            .then((response) =>{
                                
                                setSuccesfulSigninState(response)
                            })
                        }
                        //This error represents an exception within the API.
                        catch(err){
                            //logout()
                            setConnectionText("Connect")
                            setConnectionErrorMsg("")
                        }
                    })
                }

                catch(err){
                    setConnectionErrorMsg(undefined)
                    setUserAuthExists(false)
                    setConnectionText("Connect")
                    return(err)
                    //logout()
                }

            })
            
        }
        //This error can either be from a failure to reach the backend API endpoint, or an internal exception thrown in the API.
        catch(err){
            //logout()
            setConnectionText("Connect")
            setConnectionErrorMsg("")
            return(err)
        }
    }

    const signOut = async () => {
        setHeaders()
        try{
            await axios.get(`${CONFIG.API_ENDPOINT}/users/signout`)
            .then(()=>{
                clearCardCounts()   //TODO:  Move clearCardCounts into their respective pages.
                decks.clear()
                ownedPacks.clear()
                setConnectionText("Connect")
                setConnectionErrorMsg(undefined)
                setUserRoles(undefined)
                setUserName(undefined)
                setUserRank(0)
                setCompendium(new HashMap())
                setWebToken(undefined)
                setUserAddress(null)
                setUserAuthExists(false)
                setHasPacks(false)
                compendium.clear()
                decks.clear()
                deckCardCounts.clear()
                localStorage.removeItem('currentUser')
                dispatchAuthUpdate(
                    {
                        type: 'clearAuthState',
                    }
                );

            })
        }
        catch(err){
            console.error(err)
        }
    }

    const isAuthorized = async () => {
        setHeaders()        
        try{
            await axios.get(`${CONFIG.API_ENDPOINT}/users/authenticated`)
            .then(response => {
                setSuccesfulSigninState(response)
                return(response)
            })
        }
        catch(err){
            setConnectionText("Connect")
            setUserAuthExists(false)
            dispatchAuthUpdate(
                {
                    type: 'updateAuth',
                    payload: {
                        userAuthorized: false,
                        authToken: undefined,
                        userAddress: undefined,
                        chainId: undefined,
                        authChecked: true,
                    }
                }
            );
            return(false)
        }
    }

    const getUserRole = async (publicAddress) => {
        setHeaders()        
        await axios.get(`${CONFIG.API_ENDPOINT}/users/getrole?publicAddress=${publicAddress}`)
        .then(res => {
            setUserRoles(res.data)  
        })
        .catch(err => {
            setUserRoles("")
        })
    }

    const updateUserName = async (uName) => {
        setHeaders()
        return await axios.post(`${CONFIG.API_ENDPOINT}/users/profile/username`, {UserName: uName})
        .then(res => {
            dispatchAuthUpdate({type:'updateUsername', payload: { userName: res.data.userName }})
            setUserName(res.data.userName)

        })
        .catch(err => {
            return null
        })
    }

    const handleSignMessage = async (publicAddress, nonce, signer) => {
        if (publicAddress.toLowerCase() !== signer.provider.provider.selectedAddress.toLowerCase()) {
            return null;
        }
        
        var signState =  await signAuthenticationMessage(publicAddress, nonce, signer, walletType)

        if(signState && signState.code)
        {
            // woops error
            setError(signState)
        }
        return signState

    }

    const getBaseCards = async () => {
        setHeaders()
        return await axios.get(`${CONFIG.API_ENDPOINT}/basecards`)
        .then(res => {
            const cards = res.data.sort((a,b) => {
                if(a.cardName.toLowerCase() > b.cardName.toLowerCase() )
                    return 1

                else if(a.cardName.toLowerCase() < b.cardName.toLowerCase() )
                    return -1
            })
            setBaseCards(res.data)
        })
        .catch(err => {})
    }

    const createBaseCard = async (card) => {
        setHeaders()
        return await axios.post(`${CONFIG.API_ENDPOINT}/basecards`, card)
        .then(res => {
            return res
        })
        .catch(err => {})
    }

    const createCardPack = async (pack) => {
        setHeaders()
        return await axios.post(`${CONFIG.API_ENDPOINT}/cardpacks`, pack)
        .then(res => {
            return res
        })
        .catch(err => {})
    }

    const addPackDistribution = async (dist) => {
        setHeaders()
        return await axios.post(`${CONFIG.API_ENDPOINT}/cardpacks/createdistribution`, dist)
        .then(res => {
            return res.data
        })
        .catch(err => {})
    }

    const getCardPacks = async () => {
        setHeaders()
        return await axios.get(`${CONFIG.API_ENDPOINT}/cardpacks/store`)
        .then( async res => {
            let packDetails = []
            await res.data.forEach( pack => {
                let packObject = pack

                let displayName = packNameDBToUINameMap.get(pack.packName);
                packObject.displayName = displayName;
                packDetails.push( packObject)
            })
            return packDetails;
        })
        .catch(err => {console.log(err)})
    }

    const getCardPackDetails = async (packId) => {
        setHeaders()
        return await axios.get(`${CONFIG.API_ENDPOINT}/cardpacks/store/${packId}`)
        .then(async res => {
            let pack = res.data
            let containedRarities = []

            await getRaritySlots(pack.packId).then((response) => {
                response.map((slot) => {
                    slot.colors.map((chance, index) => {
                        if(!containedRarities.some(rarityId => (rarityId === chance.item1))){
                            containedRarities.push(chance.item1)
                        }
                    })
                    pack.raritySlots = response;
                })
                pack.containedRarities = containedRarities;
            })
            .catch(err => {
                console.log(err)
            })

            let displayName = packNameDBToUINameMap.get(pack.packName);
            pack.displayName = displayName;

            return pack;
        })
        .catch(err => {console.log(err)})
    }

    const getChowBundleDetails = async (bundleId) => {
        setHeaders()

        let bundle = {
              basePrice: CHOW_BUNDLES[bundleId].basePrice,
              salePrice: CHOW_BUNDLES[bundleId].salePrice,
              displayName: CHOW_BUNDLES[bundleId].displayName,
              bundleName: CHOW_BUNDLES[bundleId].bundleName,
              chowInBundle: CHOW_BUNDLES[bundleId].chowInBundle
        }
        // return await axios.get(`${CONFIG.API_ENDPOINT}/cardpacks/store/${packId}`)
        // .then(async res => {
        //     let pack = res.data
        //     let containedRarities = []

        //     await getRaritySlots(pack.packId).then((response) => {
        //         response.map((slot) => {
        //             slot.colors.map((chance, index) => {
        //                 if(!containedRarities.some(rarityId => (rarityId === chance.item1))){
        //                     containedRarities.push(chance.item1)
        //                 }
        //             })
        //             pack.raritySlots = response;
        //         })
        //         pack.containedRarities = containedRarities;
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     })

        //     let displayName = packNameDBToUINameMap.get(pack.packName);
        //     pack.displayName = displayName;

        //     return pack;
        // })
        // .catch(err => {console.log(err)})
        return bundle

        
    }


    const hasAnyPacks = async () => {
        setHeaders()
        await axios.get(`${CONFIG.API_ENDPOINT}/cardpacks/hasany`)
        .then(res => {
            setHasPacks(res.data)
            return res.data
        })
        .catch(err => {})
    }

    const getRaritySlots = async (packId) => {
        setHeaders()
        return await axios.get(`${CONFIG.API_ENDPOINT}/cardpacks/rarityslots?packId=${packId}`)
        .then(res =>  {
            let raritySlots = [];
            res.data.map((slot) =>{
                raritySlots.push(slot)
            })
            return (raritySlots)
        })
        .catch(() => undefined)
    }

    const getPromoDrops = async (packId) => {
        setHeaders()
        return await axios.get(`${CONFIG.API_ENDPOINT}/cardpacks/promodrops?packId=${packId}`)
        .then(res => res.data)
        .catch(() => undefined)
    }

    const addCardsToInventory = async (addRequest) => {
        setHeaders()
        return await axios.post(`${CONFIG.API_ENDPOINT}/cardinventory/add`, addRequest)
        .then(res => {
            let compendiumDictionary = compendium
            var addedCards = []
            if (compendium.size === 0)
                compendiumDictionary = compendiumUtil.getCompendiumDictionary()
            
            res.data.forEach(card => {
                var newCard = compendiumDictionary.get(card.mediaHash)
                newCard.count = card.count
                compendiumDictionary.set(card.mediaHash, newCard)
                addedCards.push(card)

            })

            setCompendium(compendiumDictionary)
            return(addedCards)
        })
        .catch(err => {
            console.log(err)
            return "An error occured.  Please go into the profile page and sync your wallet."
        })
    }

    const addPromoCardsToInventory = async (addRequest) => {
        setHeaders()

        await axios.post(`${CONFIG.API_ENDPOINT}/cardinventory/addpromos`, addRequest)
        .then(res => {
            let compendiumDictionary = compendium
            
            if (compendium.size === 0)
                compendiumDictionary = compendiumUtil.getCompendiumDictionary()
            
            res.data.forEach(card => {
                var newCard = compendiumDictionary.get(card.mediaHash)
                newCard.count = card.count
                compendiumDictionary.set(card.mediaHash, newCard)
            })

            setCompendium(compendiumDictionary)
            setHasPacks(true)
            setLootRewards(res.data)
        })
        .catch(err => {
            console.log(err)
            return "An error occured.  Please go into the profile page and sync your wallet."
        })
    }

    //A special function for the Feeling Lucky store purchase.  Generates a card for a single token.
    const feelingLucky = async (request) => {
        setHeaders()

        return await axios.post(`${CONFIG.API_ENDPOINT}/cardinventory/add`, request)
        .then(res => {
            return (res.data)
        })
        .catch(err => {
            console.log(err)
            return []
        })
    }

    const getCardCounts = async () => {
        setHeaders()
        await axios.get(`${CONFIG.API_ENDPOINT}/cardinventory`)
        .then(res => {
            var compendiumDictionary = compendiumUtil.getCompendiumDictionary()

            res.data.forEach(card => {
                var item = compendiumDictionary.get(card.mediaHash)
                if(item !== undefined){

                    item.count = card.count
                    item.playable = card.playable
                    compendiumDictionary.set(card.mediaHash, item)
                }
                else{
                    item.playable = card.playable
                }
            })

            setCompendium(compendiumDictionary)
        })
        .catch(error => {
            return null
        })
    }

    const clearCardCounts = async () => {
     
        if(compendium && compendium.size > 0)
        {
            await compendium.values().forEach(card => { 
                    card.count = 0
            })
                
            setChildUpdateNeeded(true);
        }
    }

    const getDecks = async () => {
        setHeaders()        
        await axios.get(`${CONFIG.API_ENDPOINT}/decks`)
        .then(res => {
            res.data.forEach(deck => {
                decks.set(deck.deckId, deck)
            })

            setChildUpdateNeeded(true)
        })
        .catch(err => {})
    }

    const getValidDecks = async () => {
        setHeaders()
        await axios.get(`${CONFIG.API_ENDPOINT}/decks/validdecks`)
        .then(res => {
            res.data.forEach(deck => {
                validDecks.set(deck.deckId, deck)
            })

            setChildUpdateNeeded(true)
        })
        .catch(err => {})
    }

    const getCardCountsInDeck = async (deckId) => {
        setHeaders()
        await axios.get(`${CONFIG.API_ENDPOINT}/decks/cardcounts?deckId=${deckId}`)
        .then(res => {
            res.data.forEach(card => {
                var copyOfCard = compendiumUtil.getCopyOfEntryForCardId(card.mediaHash)
                
                var item = {
                    cardId: copyOfCard.cardId,
                    name: copyOfCard.name,
                    edition: copyOfCard.edition,
                    rarity: copyOfCard.rarity,
                    rarityId: copyOfCard.rarityId,
                    count: card.count,
                    invalidCount: card.invalidCount
                }

                deckCardCounts.set(item.cardId, item)
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    const verifyDeckById = async (deckId) => {
        setHeaders()
        return await axios.put(`${CONFIG.API_ENDPOINT}/decks/${deckId}/verify`)
        .then(res => {
            return res.data
        })
        .catch(() => {
            if (deckId === 1) {
                return [{cardId: 1, isValid: true}]
            } 
            else if (deckId === 2) {
                return [{cardId: 1, isValid: false}]
            }
            
            return undefined;
        })
    }

    const createDeck = async (request) => {
        setHeaders()
        let deckId = null;
        await axios.post(`${CONFIG.API_ENDPOINT}/decks/createdeck`, request)
        .then(res => {
            decks.set(res.data.deckId, res.data)
            deckId = res.data.deckId
        })
        .catch(err => {})

        return deckId;
    }

    const addCardToDeck = async (request) => {
        setHeaders()

        await axios.post(`${CONFIG.API_ENDPOINT}/decks/addcard`,  {deckId:request.deckId, mediaHash:request.cardId})
        .then(() => {

            //Increment the total number of cards added to the deck
            decks.get(request.deckId)['numCards'] ++;
            
            //Grab a copy of the card from the compendium for necessary info
            let copyOfCard = compendiumUtil.getCopyOfEntryForCardId(request.cardId);

            //Get the requested card from the card count map
            let updatedCard = deckCardCounts.get(request.cardId)

            //If this is the first instance of this card added to the deck, populate necessary info
            if (updatedCard === undefined || updatedCard === null) {
                
                updatedCard = {
                    cardId: copyOfCard.cardId,
                    name: copyOfCard.name,
                    edition: copyOfCard.edition,
                    rarity: copyOfCard.rarity,
                    rarityId: copyOfCard.rarityId,
                    count: 1,
                    invalidCount: 0
                }

                deckCardCounts.set(request.cardId, updatedCard)
            }
            //If this card already exists in the list, just increment the card count
            else{
                deckCardCounts.get(request.cardId)['count'] ++;
            }
            
            
            setChildUpdateNeeded(true)
        })
        .catch(err => {})
    }

    const removeCardFromDeck = async (request) => {
        setHeaders()
        return await axios.post(`${CONFIG.API_ENDPOINT}/decks/removecard`, {deckId:request.deckId, mediaHash:request.cardId})
        .then(() => {
            
            //First update the selected deck by decrementing its total card counter.
            

            //Update the deckCardCounts to remove 
            let removedCard = deckCardCounts.get(request.cardId)
            
            if(removedCard.invalidCount > 0){
                let compendiumCard = compendium.get(request.cardId)

                if(compendiumCard){

                    compendiumCard.count = Math.max(0, compendiumCard.count - 1)
                    compendium.set(request.cardId, compendiumCard)
                }

                removedCard.invalidCount--
            }

            if(removedCard.count === 0){
                deckCardCounts.remove(request.cardId)
            }
            else if(removedCard.count > 0){
                decks.get(request.deckId)['numCards'] --;
                deckCardCounts.get(request.cardId)['count']--;
            } 
            else{
                // what sort of fuckery happened here
                // the server should have told us to fuck off
                return;
            }
            setChildUpdateNeeded(true)
        })
        .catch(err => {})
    }

    const deleteDeck = async (deckId) => {
        setHeaders()
        await axios.post(`${CONFIG.API_ENDPOINT}/decks/deletedeck?deckId=${deckId}`)
        .then((res) => {
            decks.delete(deckId)
            setChildUpdateNeeded(true)
        })
        .catch(err => {
        })
    }

    const editDeckName = async (deck) => {
        setHeaders()
        await axios.put(`${CONFIG.API_ENDPOINT}/decks/edit/name`, deck)
        .then((res) => {
            let deckLocal = decks.get(res.data.deckId)
            decks.delete(deck.DeckId)
            deckLocal.deckName = res.data.deckName
            decks.set(deck.deckId, deckLocal)
            setChildUpdateNeeded(true)
        })
        .catch(err => {})
    }

    const editDeckDescription = async (deck) => {
        setHeaders()
        await axios.put(`${CONFIG.API_ENDPOINT}/decks/edit/description`, deck)
        .then((res) => {
            let deckLocal = decks.get(res.data.deckId)
            decks.delete(deck.DeckId)
            deckLocal.description = res.data.description
            decks.set(deck.deckId, deckLocal)
            setChildUpdateNeeded(true)
        })
        .catch(err => {})
    }

    const downForMaintenanceAsync = async () => {
        setHeaders()
        return await axios.get(`${CONFIG.API_ENDPOINT}/configuration/isbetalive`)
        .then((res) => {
            return res.data
        })
        .catch(err => {
            console.log(err)
            return false
        })
    }

    const mockConvertWinningsToHow = async ({value}) =>{
        const convertStatus = await setTimeout(()=>{
            return( 
            {
                status: 1,
                events:[
                    {
                        'tokenTransfer':{
                            args:{
                                transferAmount: value
                            }
                        }

                    }
                ]
                
            });
        }, 5000)
        return convertStatus;
    }

    const mockUpdateUserWinnings = async ({claimWinningsRequest, callbackFn}) =>{
        
        var mockResponse = {};
        callbackFn({type:'claimAsChowInitiated', payload:{reason:'In Progress'}})
        
        return await setTimeout( ()=>{
            

            if(claimWinningsRequest.claimedAs === 'chow'){
                setChowOwned(chowOwned+claimWinningsRequest.amountClaimed)
                setEarnedChow(earnedChow - claimWinningsRequest.amountClaimed)
            }

            mockResponse = {
                status: 200,
                data: { amountClaimed: claimWinningsRequest.amountClaimed }
            }

            callbackFn({type:'transactionComplete', payload: mockResponse})
            
        }, 5000)

    }

    return (
        <AoeApiContext.Provider value = {{
            getUserByAddress,
            signIn,
            signOut,
            isAuthorized,
            updateUserName,
            getUserRole,
            getBaseCards,
            createBaseCard,
            createCardPack,
            getCardPacks,
            hasAnyPacks,
            getRaritySlots,
            getPromoDrops,
            addPackDistribution,
            addCardsToInventory,
            addPromoCardsToInventory,
            feelingLucky,
            getCardCounts,
            getDecks,
            getValidDecks,
            verifyDeckById,
            getCardCountsInDeck,
            createDeck,
            addCardToDeck,
            removeCardFromDeck,
            deleteDeck,
            editDeckName,
            editDeckDescription,
            asyncSyncWalletTokens,
            downForMaintenanceAsync,
            getCardPackDetails,
            mockConvertWinningsToHow,
            mockUpdateUserWinnings,
            getChowBundleDetails
        }}>

        {children}

        </AoeApiContext.Provider>
    )
}

export const useAoeApiClient = () => useContext(AoeApiContext);