import hashmap from "hashmap";
import React from "react";
import { Grid, Popover, Backdrop } from '@material-ui/core';
import CONFIG from "../../config/config";
import styled, {keyframes, css} from 'styled-components'
import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import { Zoom } from "@material-ui/core";

const SupportedChainButton = styled(Grid)`
    font-weight: bold;
    height:auto;
    font-size: 2vh;
    height: 5vh;
    color: white;
    opacity: 0.7;
    border-radius: 12px;
    background: linear-gradient( rgba( 208, 80, 0, 0 ) 20%, rgba( 255, 220, 0, 0 )  100%);
    cursor: pointer;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    vertical-align: center;
    padding-top: 1vh;
    transition: all  ease 0.3s;
    &:hover{
        opacity: 1;
        background: linear-gradient( rgba( 208, 80, 0, .5 ) 20%, rgba( 255, 220, 0, .4 )  100%);
    }
`
const radiate = keyframes`
  from {
      filter: brightness(1.3) drop-shadow(0px 0px 4px rgba(255,159,0,.5));
  }
  to {
      filter: brightness(1)  drop-shadow(0px 0px 1px rgba(255,159,0,.1));
  }
`

const radiateAnimation = props => 
css`
  ${radiate} infinite alternate-reverse 2000ms;
`

const StyledPopover = styled(Popover)`
    background-size: 100% 100%;
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(0px 5px 4px black);
    animation: ${ radiateAnimation };
  }
`



const chainModalRef = React.createRef()

const SupportedChainModal = Demodal.create(({dialogueText}) => {
    const chainModal = useModal()
  
    const resolve = value => () => {
      chainModal.resolve(value)
      chainModal.close()
    }
  
    return (
        <Backdrop onClose={()=>chainModal.close()} {...muiDialog(chainModal)} style={{backgroundColor:'rgba(0,0,0,.8)', zIndex: 700}}>
            <StyledPopover 
                id={'select-chain-modal-dialogue'} 
                ref={chainModalRef}
                {...muiDialog(chainModal)}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                        },
                }}

                style={{
                
                    filter:`drop-shadow( 0px 0px 25px rgba(0,0,0, 1))`, 
                    justifyContent:'center', 
                    alignItems:'center', 
                    color: 'white', 
                    alignItems:'center', 
                    margin:'auto',
                }}
                anchorReference="anchorPosition"
                anchorPosition={{top:window.innerHeight/2, left:window.innerWidth/2}}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
              
                <Grid 
                    container 
                    style={{
                        background: 'rgba(0,0,0,1)', 
                        color: 'white', 
                        justifyContent:'center', 
                        alignItems:'center', 
                        height:'auto', 
                        width:'100%', 
                        borderRadius:'12px',
                        overflow:'hidden'
                    }}
                    classes = {{paper: "" }}
                    >

                        <Grid item style={{color:'white', fontWeight:'bold', fontSize:'5vh', marginBottom:'10px', textAlign:'center'}}> 
                            {dialogueText} 
                        </Grid>

                    {
                        CONFIG.SUPPORTED_CHAINS.values().map((chain) =>
                            <Grid item style={{width:'100%', justifyContent:'center', alignItems:'center', height:'100%'}}  key={chain.name}>
                                <SupportedChainButton 
                                    value={chain.chainId}
                                    onClick={ resolve(chain.chainId)}
                                >
                                        {chain.name}
                                </SupportedChainButton>
                            </Grid>
                        )
                    }
                </Grid>
            </StyledPopover>
        </Backdrop>
    )
  })

export default SupportedChainModal;