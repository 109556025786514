import {Grid, Fab} from "@material-ui/core"
import StyledCardContainer from "../components/StyledCardContainer"
import nextId from "react-id-generator";
import { useWindowDimensions } from '../helpers/windowDimentionProvider';
import { Close } from '@material-ui/icons';

const getCardHeight = (width) => {
    if(width > 2900){
        return 252
    }
    else if(width > 2220 && width <= 2900){
        return 189
    }
    else if(width > 1920 && width <= 2220){
        return 147
    }
    else if(width >= 1600 && width <= 1920){
        return 147
    }
    else if(width >= 1300 && width < 1600){
        return 147
    }
    else if(width >= 900 && width < 1200){
        return 126
    }
    else{
        return 126
    }
}

const getCardWidth = (width) => {
    if(width > 2900){
        return 180
    }
    else if(width > 2220 && width <= 2900){
        return 135
    }
    else if(width >= 1920 && width <= 2220){
        return 105
    }
    else if(width >= 1600 && width < 1920){
        return 105
    }
    else if(width >= 1300 && width < 1600){
        return 105
    }
    else if(width >= 900 && width < 1200){
        return 90
    }
    else{
        return 90
    }
}

const CardsInDeck = ({deckId, deckCardCounts, total, capacity, serverRequestInProgress, removeCardCallback, enableCardPopoverCallback, setSelectedCardCallback}) => {

    const {width, height} = useWindowDimensions();

    const displayCardsPerCount = (cardInDeck, removeCardCallback) =>{

        var totalCardsOfType = 0;
        var itemsToDisplay = [];
    
        for(totalCardsOfType; totalCardsOfType < cardInDeck.count; totalCardsOfType++){
            itemsToDisplay.push(

            <div 
            key={nextId()} 
            style={{margin:'0px', height:getCardHeight(width), marginBottom:'10px', marginBottom:'-30px'}}
            >
                <Fab 
                size="small" 
                aria-label="remove" 
                color="secondary"
                style={{ position:'relative', left:width > 2220? getCardWidth(width) * .7: getCardWidth(width) * .6, top:getCardHeight(width) * .1, margin:'0px', zIndex:'800', filter: 'drop-shadow(0 0 7px black)' }}
                onClick = {serverRequestInProgress?()=>{console.log('Server Request Currently In progress')}:()=>{removeCardCallback()}}
                disabled={serverRequestInProgress}
                >
                    <Close style = {{scale: "1.25"}} />
                </Fab>
                <div 
                style={{
                    height: getCardHeight(width),
                    width: getCardWidth(width), 
                    zIndex: 533, margin:'5px', 
                    backgroundColor: cardInDeck.invalidCount>0?'#ff0000':'rgba(0,0,0,0)',
                    padding: cardInDeck.invalidCount>0?'4px':'0px', 
                    borderRadius:'5px', marginTop:'-35px'}}>
                <StyledCardContainer
                key={nextId()} 
                imgName={cardInDeck.cardId}
                rarity={cardInDeck.invalidCount === 0 ? cardInDeck.rarity : "0"}
                count={cardInDeck.count}
                width={getCardWidth(width)+'px'}
                height={getCardHeight(width)+'px'}
                zIndex={533}
                onClick={()=>{setSelectedCardCallback({cardId:cardInDeck.cardId})}}
                hover
                />
                </div>
            </div>
        )}
    
        return(
            itemsToDisplay
        )
    }

    return(
        <div 
        key={nextId()} 
        style={{ 
            position:'fixed', 
            top:'100%', 
            left:'0px', 
            width:'100vw', 
            filter:'drop-shadow(0 -15px 10px #000)', 
            zIndex:700}}
        >
            <div 
            key={nextId()} 
            style={{
                fontSize:'26px', 
                fontWeight:'bold', 
                borderRadius:'5px 5px 0 0', 
                color:'white', 
                width:'100px', 
                position:'fixed', 
                top:'100%', 
                left:'50%',
                backgroundColor:'rgba(0,170,0,1)', 
                height:'26px', 
                zIndex:700, 
                justifyContent:'center', 
                alignItems:'center', 
                marginTop:'-'+getCardHeight(width)-86+'px', 
                marginLeft:'-50px', 
                textAlign:'center', 
                padding:'5px'}}
            >
                <div style={{marginTop:'-4px'}}>
                    {total} / {capacity}

                </div>
            </div>
            
            <Grid key={nextId()} container style={{position:'fixed', top:'100%', left:'0px', width:'100vw', backgroundColor:'rgba(50,60,50,1)', height:getCardHeight(width)+50, marginTop:'-'+getCardHeight(width)-50+'px', zIndex:650, overflowY:'scroll',  justifyContent:'center', alignItems:'center', paddingBottom:'45px'}} className='cardsInDeckTopDivider'>
            {
                deckCardCounts.values().map(cardInDeck=>
                    displayCardsPerCount(cardInDeck, removeCardCallback.bind(this, cardInDeck, deckId), setSelectedCardCallback) 
                )
            }
            </Grid>
        </div>
    )
}

export default CardsInDeck;