import React, { createContext, useContext, useState, useReducer, useEffect } from "react";
//import { useAoeApiClient } from "./AoeApiClient";

export const AuthReducer = createContext();

const isNullUndefinedOrEmpty = (obj) => {
    return( obj === undefined || obj === null || obj === ''  )
} 

export const AuthReducerProvider = ({children}) => {

    const [authState, dispatchAuthUpdate] = useReducer(
        authReducer, 
        {
            authToken: undefined, 
            userAuthorized: false,
            userAddress: undefined,
            chainId: undefined,
            authChecked: false,
            truncatedAddress: undefined,
            userName: undefined,
            userRank: undefined,
        }
    );
    //const {isAuthorized} = useAoeApiClient();

    function authReducer(authState, action) {
        switch (action.type) {
            case 'clearAuthState': {
                return {
                    ...authState,
                    userAuthorized: false,
                    authToken: undefined,
                    userAddress: undefined,
                    chainId: undefined,
                    authChecked: false,
                    truncatedAddress: undefined,
                    userName: undefined,
                    userRank: undefined
                };
            }
            case 'updateAuth': {
                return {
                    ...authState,
                    userAuthorized: action.payload.userAuthorized,
                    authToken: action.payload.authToken,
                    userAddress: action.payload.userAddress,
                    chainId: action.payload.chainId,
                    authChecked: action.payload.authChecked,
                    truncatedAddress: action.payload.userAddress?
                        action.payload.userAddress.substring(0,6) + "..." + 
                        action.payload.userAddress.substring(
                            action.payload.userAddress.length - 4, 
                            action.payload.userAddress.length
                        ): '',
                        userName: action.payload.userName,
                        userRank: action.payload.userRank
                }
            }
            case 'updateUsername':{
                return{
                    ...authState,
                    userName: action.payload.userName
                }
            }
            case 'updateUserData':{
                return{
                    ...authState,
                    userName: action.payload.userName,
                    userRank: action.payload.userRank
                }
            }
        }
    }

    // const handleAuthUpdate = async (action) => {


    //     if(action === 'updateAuth'){
            
    //         dispatchAuthUpdate({ type: 'updateAuth', payload: paymentManager});
    //     }
    //     else{
    //         dispatchAuthUpdate({ type: 'clearAuthState'});
    //     }
    // }

    // const checkForUserAuth = async () => {
    //     //const authResults =  await isAuthorized()
    //     if(isNullUndefinedOrEmpty(authResults)){
    //         handleAuthUpdate({type:'clearAuthState'})
    //     }
    //     else if(authResults === false){
    //         handleAuthUpdate({type:'clearAuthState'})
    //     }
    //     else{
    //         handleAuthUpdate({type:'updateAuth', payload:{userAuthorized: true, authToken: authResults}})
    //     }
    // }

    // useEffect(() => {
    //     Promise.resolve(checkForUserAuth())
    // }, [])
        
        return (
        <AuthReducer.Provider 
            value = {{
                authState, 
                dispatchAuthUpdate
            }}
        >
            {children}
        </AuthReducer.Provider>
        )

}
export const useAuthReducer = () => useContext(AuthReducer);