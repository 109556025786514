//This component combines duplicate behavior from the Store and Inventory Packs (whats inside feature) page into one shared component.
//Returns a grid container housing all the elements that should be displayed in the popover.

import React, { useState, useEffect, useReducer } from "react";
import { makeStyles, Grid, Typography, Zoom, CircularProgress, Dialog, Popover, Backdrop, Card, Divider, Button, TextField, Modal } from '@material-ui/core';
import { rarityColors } from "../../helpers/GlobalStore";
import SlotChance from '../../components/SlotChance';
import StyledCardContainer from "../StyledCardContainer";
import { useContractReducer } from "../../helpers/ContractReducer";
import styled, {keyframes, css} from 'styled-components'
import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import { useStore } from "../../helpers/GlobalStore";
import CONFIG from "../../config/config";
import { useAoeApiClient } from "../../helpers/AoeApiClient";
import CompendiumUtil from "../../pages/compendium/CompendiumUtil";
import { useAuthReducer } from "../../helpers/AuthReducer";

const MAX_ALLOWED_MINTS_PER_BLOCK = 50;

const buyAndMintModalRef = React.createRef()



const radiate = keyframes`

    from {
        filter: brightness(1.3) drop-shadow(0px 0px 4px #ff9f00);

    }
    to {
        filter: brightness(1)  drop-shadow(0px 0px 1px #cf8f30);

    }
`

const radiateAnimation = props => 
css`
    ${radiate} infinite alternate-reverse 2000ms;

`

const StyledPopover = styled(Popover)`

    background-size: 100% 100%;
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(0px 5px 4px black);

        animation: ${ radiateAnimation };


    }
    
`

const BuyButton = styled(Button)`

    color: black;
    font-family: "helvetica"!important;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    background: linear-gradient(#d05000 20%, #ffff00 100%);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    opacity: .75;
    text-align: center;
    margin: auto;
    width: 140px;
    height: 50px;
    transition: all linear .2s;

    &:hover{
        transform: scale(1.1);
        opacity: 1;
        background: linear-gradient(#d05000 0%, #ffff00 90%, #ffffff 100%);
        border: 1px solid white;
        border-left: none;
        border-right: none;
    }
    &:disabled{
        filter: grayscale(1);
    }
`


const isNullUndefinedOrEmpty = (obj) => {
    return( obj === undefined || obj === null || obj === ''  )
} 


const getRarityUnderlineClass = (rarity) =>{
    switch(rarity){
        case 0:
            return 'underlineFeelingLucky'; 
        case 1:
            return 'underlineLegendary';
        case 2:
            return 'underlineEpic';
        case 4:
            return 'underlineRare';
        case 8:
            return 'underlineUncommon';
        case 16:
            return 'underlineCommon';
        
    }
}

const getFloatPrecision = (value) => {

    const valueStr = value.toString().split(".")
    let precision = 0;

    if(valueStr.length === 1){
        precision = 1
    }
    else{
        precision = valueStr[1].length
    }

    return precision
}

const transactionStates ={
    'waitingForPurchaseRequest': 0,
    'interactingWithBlockchain': 1,
    'mintingStarted': 2,
    'mintingComplete': 3,
    'cardAssignmentStarted': 4,
    'cardAssignmentComplete': 5,
    'transactionComplete': 6,
    'displayingLootRewards':7,
    'transactionFailed': 10, 
}

const BuyAndMintPopover = Demodal.create(({pack, currencySymbol, chowSymbol, cardUpdateDispatch}) => {
    const styles = useStyles();
    const buyAndMintModal = useModal()
    const {paymentManagerState} = useContractReducer()
    const {currentChainId, userPackPurchaseCounts} = useStore()
    const {authState} = useAuthReducer()
    const {addCardsToInventory, feelingLucky} = useAoeApiClient()
    var compendiumUtil = CompendiumUtil();

    



    useEffect(() => {
        if(isNullUndefinedOrEmpty(paymentManagerState.paymentManagerState)){
            buyAndMintModal.close()
        }
    },[paymentManagerState])



    const resolve = value => () => {
      buyAndMintModal.resolve(value)
      buyAndMintModal.close()
    }

    const [salePopoverState, dispatchSalePopoverUpdate] = 
        useReducer(
            salePopoverReducer, {
                lootRewards: null,
                isProcessing: false,
                showDrops: false,
                purchaseSuccess: false,
                buyQuantity: 1,
                selectedPack: pack,
                transactionMessage: undefined,
                canExitModal: true,
                currentTransactionPhase: 'waitingForPurchaseRequest',
                price: pack.salePrice > 0? pack.salePrice: pack.basePrice
            }
    );

    const getCardRarity = (card) => {
        let tempCard = compendiumUtil.getCopyOfEntryForCardId(card.mediaHash)
        return tempCard.rarityId
    }
    
   
    function salePopoverReducer(salePopoverState, action) {
        switch (action.type) {
            case 'changeBuyQuantity': {
                //  dispatch({type:'clearField'})
                  return {
                        ...salePopoverState,
                        buyQuantity: action.payload
                  };
              }
            case 'transactionInitiated': {
              //  dispatch({type:'clearField'})
                return {
                    ...salePopoverState,
                    isProcessing: true,
                    canExitModal: false,
                    transactionMessage: `Confirming transaction  on ${CONFIG.SUPPORTED_CHAINS.get(currentChainId+'').name}`,
                    currentTransactionPhase: 'interactingWithBlockchain'
                };
            }
            case 'transactionComplete': {

                return {
                    ...salePopoverState,
                    isProcessing: false,
                    canExitModal: true,
                    purchaseSuccess: true,
                    currentTransactionPhase: 'transactionComplete',
                    lootRewards: action.payload
                }
            }
            case 'transactionFailed': {
                return {
                    ...salePopoverState,
                    isProcessing: false,
                    canExitModal: true,
                    transactionMessage: action.payload.reason,
                    currentTransactionPhase: 'transactionFailed',
                }
            }
            case 'mintingStarted': {
                return {
                    ...salePopoverState,
                    isProcessing: false,
                    canExitModal: false,
                    currentTransactionPhase: 'mintingStarted',
                    transactionMessage: `Now minting NFTs on ${CONFIG.SUPPORTED_CHAINS.get(currentChainId+'').name}`
                }
            }
            case 'mintingComplete': {
                return {
                    ...salePopoverState,
                    isProcessing: false,
                    canExitModal: false,
                    currentTransactionPhase: 'mintingComplete',
                    transactionMessage: `Minting completed on ${CONFIG.SUPPORTED_CHAINS.get(currentChainId+'').name}`
                }
            }
            case 'cardAssignmentStarted': {
                return {
                    ...salePopoverState,
                    isProcessing: false,
                    canExitModal: false,
                    currentTransactionPhase: 'cardAssignmentStarted',
                    transactionMessage:'Preparing to reveal your cards'
                }
            }
            case 'cardAssignmentComplete': {
                return {
                    ...salePopoverState,
                    isProcessing: false,
                    canExitModal: false,
                    currentTransactionPhase: 'cardAssignmentComplete',
                    transactionMessage: `Preparing to reveal your cards`
                }
            }

            case 'displayingLootRewards': {
                return {
                    ...salePopoverState,
                    isProcessing: false,
                    canExitModal: true,
                    purchaseSuccess: true,
                }
            }
            case 'clearState': {
                return {
                    ...salePopoverState,
                    canExitModal: true,
                    transactionMessage: '',
                    purchaseSuccess: false,
                    isProcessing: false,
                    currentTransactionPhase: 'waitingForPurchaseRequest',
                    lootRewards: [],
                    buyQuantity: 1
                }
            }
        }
    }
    
    const { lootRewards, price, isProcessing, showDrops, purchaseSuccess, buyQuantity, canExitModal, selectedPack, transactionMessage, currentTransactionPhase} = salePopoverState;

    const disablePurchaseClick = () => {

        if(isNaN(buyQuantity) || selectedPack.purchaseLimitReached || Number(buyQuantity) < 1){
            return true;
        }

        if ((parseInt(selectedPack.maxAllowedPurchases) !== 0 ) && (parseInt(selectedPack.userPurchaseCount) + parseInt(buyQuantity) > parseInt(selectedPack.maxAllowedPurchases))){
            return true;
           
        }
        
        if(selectedPack.numCards * buyQuantity > MAX_ALLOWED_MINTS_PER_BLOCK){
            return true;
        }

        return false;
    }
  

    const handleBuyClick = async () => {

       
        try{
            
            const Web3 = require('web3');
            let web3 = new Web3(CONFIG.SUPPORTED_CHAINS.get(currentChainId+"").node);
            
            const buyQuantityParsed = parseInt(buyQuantity)
    
            const precision = getFloatPrecision(price)
            const floatPrice = parseFloat(price).toFixed(precision)
            const totalPrice = ((floatPrice * buyQuantityParsed).toFixed(precision + 2)).toString()
    
            let wei = web3.utils.toWei(totalPrice);
    
            let purchaseRequest;
    



            //User requested to buy a standard pack (not Feeling Lucky)
            if(selectedPack.packName !== "Feeling Lucky"){
                purchaseRequest = await paymentManagerState.paymentManagerState.purchaseCardPack(buyQuantityParsed, selectedPack.packName, "", {value: wei})    
                dispatchSalePopoverUpdate({type:'transactionInitiated', payload:{reason:'In Progress'}})
                const purchaseResult = await purchaseRequest.wait()
                dispatchSalePopoverUpdate({type:'mintingStarted'})
                if(purchaseResult.status === 1){

                    const mintedNftsEvent = purchaseResult.events.find((evt) => evt.event === 'NftsBulkMinted');
                    
                    const newlyMintedIDs = mintedNftsEvent.args['newlyMintedIDs'];
                    
                    const mintIdStrings = newlyMintedIDs.map(id => {
                        return id.toString()
                    })
                    
                    dispatchSalePopoverUpdate({type:'mintingComplete'})
                    const addRequest = {
                        PackId: selectedPack.packId,
                        UniqueIds: mintIdStrings
                    }

                    dispatchSalePopoverUpdate({type:'cardAssignmentStarted'})
                    var addedCards = await addCardsToInventory(addRequest)
    
                    if(addedCards && addedCards.length > 0){
                        //Success case
                        dispatchSalePopoverUpdate({type:'transactionComplete', payload: addedCards})
                        // setPurchaseSuccess(undefined) //don't want to show a success/failure message, just the results
                      //  cardUpdateDispatch({ type: 'updatePackState', payload: parseInt(buyQuantity) + parseInt(selectedPack.userPurchaseCount)});
                    }
                    else {
                        //Failure case
                        dispatchSalePopoverUpdate({type:'transactionFailed', payload: {reason: 'Something failed in our server. Please go to the profile page and sync your wallet.'}})   
                    //    settransactionMessage("Purchase Failed!  Something went wrong in our server.  Please try again.")
                    //    setPurchaseSuccess(false)
                    }

                    //   setShouldRefresh(true)

    
                }
                else{
             //       settransactionMessage("Purchase Failed!")
              //      setCanExitModal(true)
               //     setPurchaseSuccess(false)
                    dispatchSalePopoverUpdate({type:'transactionFailed', payload: {reason: 'Transaction failed within the blockchain.'}})
                            
                    console.log("Transaction failed within the blockchain.")
                }
            }
            
            //User requested to buy a Feeling Lucky pack.  Call a separate api endpoint and skip logging the purchase.
            else {

                dispatchSalePopoverUpdate({type:'transactionInitiated', payload:{reason:'In Progress'}})
                purchaseRequest = await paymentManagerState.paymentManagerState.feelingLucky({value: wei})
                dispatchSalePopoverUpdate({type:'mintingStarted'})
                const purchaseResult = await purchaseRequest.wait()
                dispatchSalePopoverUpdate({type:'mintingComplete'})

                dispatchSalePopoverUpdate({type:'cardAssignmentStarted'})
              
                if(purchaseResult.status === 1){
                    
                    const tokensClaimedEvent = purchaseResult.events.find((evt) => evt.event && evt.event === 'NftMinted');
    
                    //if(tokensClaimedEvent !== undefined)
                    const newlyMintedID = tokensClaimedEvent.args['uniqueId'];
                    const request = {
                        PackId: selectedPack.packId,
                        UniqueIds: [newlyMintedID.toString()]
                    }

                    var addedCard = await feelingLucky(request);

                    //Generation succeeded, retrieved a single card.
                    if(!isNullUndefinedOrEmpty( addedCard)){
                        dispatchSalePopoverUpdate({type:'transactionComplete', payload: addedCard })

                    }

                    //Generation Failed.  Nothing given back.
                    else {
                        dispatchSalePopoverUpdate({type:'transactionFailed', payload: {reason: 'Something failed in our server.  Please go to the profile page and sync your wallet.'}})
                    }
                }
                else{
                    dispatchSalePopoverUpdate({type:'transactionFailed', payload: {reason: "Transaction failed within the blockchain."}})
                    console.log("Transaction failed within the blockchain.")
                }
            }
        }
        catch(err){
            let message;
            if (err && err.error && err.error.message){
                message = err.error.message
            } else if (err && err.message){
                message = err.message
            } else if (err && err.data && err.data.message) {
                message = err.data.message
            } else {
                message = "An unknowable error occured"
            }
    
            message = message.replace("execution reverted: ", "")
    
            //If the user requests to buy past their limit, give a more helpful error.
            if(message === "This account has reached the purchase limit for this pack"){
                let max
                let ownedPack = ownedPacks.get(selectedPack.packId)
                
                if(ownedPack === undefined)
                    max = selectedPack.maxAllowedPurchases
    
                else {
                    max = selectedPack.maxAllowedPurchases - ownedPack.numBought
                }

                message = `You can only buy ${max} of these packs!`
            }
            dispatchSalePopoverUpdate({type:'clearState', payload: {reason:""}})
        }
    }
    
    
    const showLootRewards = () => {

        return(
            <Grid container style={{justifyContent:'center', alignItems:'center', textAlign:'center'}}>
            {lootRewards && lootRewards.map((card, index) => (
                <Zoom 
                in = {lootRewards !== undefined && lootRewards.length > 0}
                style = {{
                    transitionDelay: `${150 + ((index + 1) * 200)}ms`
                }}
                key = {`lootItem-${card.mediaHash}-Zoom`}
                >
                    <Grid item key={`lootItem-${card.mediaHash}-MainContainer`} style={{justifyContent:'center', alignItems:'center', textAlign:'center'}}>
                        <Grid container style={{justifyContent:'center', alignItems:'center', textAlign:'center'}}>
                            <Grid key = {`lootItem-${card.mediaHash}-CardContainer`}  item  style={{height:'210px', width:'150px'}}>
                                <StyledCardContainer
                                rarity={getCardRarity(card)} 
                                count={1} 
                                imgName={card.mediaHash} 
                                dropShadow
                                />
                            </Grid>

                            <Grid item key = {`lootItem-${card.mediaHash}-TextContainer`} xs = {12}>
                            <Typography
                            variant = "body1"
                            >
                                {`x ${card.count}`}
                            </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Zoom>
            ))
    
            }
            </Grid>
        )
    }
    

    const selectQuantityAndBuyScreen = () =>{

        return(
            <Grid item style={{ height: 'auto', display:'flex'}}>
                <Grid container spacing = {2} style={{justifyContent:'center', alignItems:'center'}}>

                    <Grid 
                        item 
                        xs = {12} 
                        className={getRarityUnderlineClass(selectedPack.rarity)}
                        style = {{
                            marginBottom: 10,
                            color: `rgb(${rarityColors[selectedPack.rarity]})`,
                        }}
                    >
                        <div 
                        className='fontify'
                        style={{ 
                            textAlign: "center", 
                            color: `rgb(${rarityColors[selectedPack.rarity]})`,
                        }}
                        >
                            {selectedPack.displayName &&
                                selectedPack.displayName
                            }
                        </div>
                    </Grid>


                    <Grid
                        item 
                        xs={12} 
                        style={{
                            marginBottom: 10,     
                        }}
                    >

                    </Grid>

                    <Grid item xs = {12} style={{marginBottom:'10px', marginTop:'10px'}}>
                {/* 
                        {selectedPack.numCards &&
                        <Typography variant = "body1" style = {{textAlign: "center"}}>
                            {`(${selectedPack.numCards} cards per pack)`}
                        </Typography>
                        } 
                */}
                        {selectedPack.chowInBundle &&
                            <Typography variant = "body1" style = {{textAlign: "center"}}>
                                {`${selectedPack.chowInBundle} cHoW per bundle`}
                            </Typography>
                        }
                    </Grid>

                    <Grid item xs = {12}>

                        <Grid container style={{justifyContent:'center', alignItems:'center'}} spacing = {3}>
                            {selectedPack.chowInBundle?
                                  
                                <Grid item>
                                    <Grid item>
                                        <BuyButton
                                        disabled = { disablePurchaseClick()}
                                        onClick = {() => handleBuyClick()}
                                        >
                                            Buy
                                        </BuyButton>
                                    </Grid>
                                </Grid>
                            :
                            <>
                                {/* 
                                <Grid item>
                                    <BuyButton
                                    disabled = { disablePurchaseClick()}
                                    onClick = {() => handleBuyClick()}
                                    >
                                        Buy with <>{currencySymbol&& currencySymbol}</>
                                    </BuyButton>
                                </Grid>
                            
                                <Grid item>
                                    <BuyButton
                                    disabled = { disablePurchaseClick()}
                                    onClick = {() => handleBuyClick()}
                                    >
                                        Buy with <>{chowSymbol&& chowSymbol}</>
                                    </BuyButton>
                                </Grid> 
                                */}
                                <Grid item>
                                    <BuyButton
                                        disabled = { disablePurchaseClick()}
                                        onClick = {() => handleBuyClick()}
                                    >
                                        Mint Hound{buyQuantity && buyQuantity>1 && 's'}
                                    </BuyButton>
                                </Grid>
                            </>
                      
                      
                            }
                            <Grid item>
                                <Grid container style={{justifyContent:'center', alignItems:'center'}}>
                                    <Grid item  style={{marginRight:'10px'}}>
                                    {!selectedPack.chowInBundle &&
                                        currencySymbol
                                    }
                                    </Grid>

                                    <Grid item>
                                        <Typography variant = "h6">{(Number(price) * Number(buyQuantity)).toFixed(6)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid 
                            item 
                            style = {{
                                marginRight: 15,
                                marginLeft: 20,
                            }}
                            >
                                <TextField 
                                id="standard-textarea"
                                label = "Quantity"
                                variant="outlined"
                                type="number"
                                className = {styles.numFieldProp}
                                    InputProps = {{className: styles.numFieldProp}}
                                    InputLabelProps = {{className: styles.numFieldLabel}}
                                value = {buyQuantity}
                                error={    (((parseInt(selectedPack.maxAllowedPurchases) !== 0) &&
                                           ((parseInt(selectedPack.userPurchaseCount) + parseInt(buyQuantity)) > parseInt(selectedPack.maxAllowedPurchases))) || 
                                            (selectedPack.numCards * buyQuantity > MAX_ALLOWED_MINTS_PER_BLOCK))
                                    }
                                helperText = {( (parseInt(selectedPack.maxAllowedPurchases) !== 0) && parseInt(selectedPack.userPurchaseCount) + parseInt(buyQuantity) > parseInt(selectedPack.maxAllowedPurchases)) ? 
                                                "You have reached the user purchase limit for this pack type!" : 
                                                (selectedPack.numCards * buyQuantity > MAX_ALLOWED_MINTS_PER_BLOCK)?  
                                                `Block chain only allows 50 total mints per block, attempting to mint: ${selectedPack.numCards * buyQuantity}`:""
                                }
                                onChange = {(e) => dispatchSalePopoverUpdate({type: 'changeBuyQuantity', payload: e.target.value})}
                                />
                            </Grid>
                
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    

    const handleModalClose = () => {
        dispatchSalePopoverUpdate({type:'clearState'})
        buyAndMintModal.close()
    }


    return (
        <Backdrop onClose={canExitModal?()=>handleModalClose():()=>{}} {...muiDialog(buyAndMintModal)} style={{backgroundColor:'rgba(0,0,0,.8)', zIndex:'700'}}>
            <StyledPopover 
                {...muiDialog(buyAndMintModal)}
                ref={buyAndMintModalRef}
                onClose={canExitModal?()=>handleModalClose():()=>{}}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                        },
                }}

                style={{
                    width:'auto', 
                    filter:`drop-shadow( 0px 0px 25px rgba(${rarityColors[pack.rarity]}, 1))`, 
                    justifyContent:'center', 
                    alignItems:'center', 
                    color: 'white', 
                    alignItems:'center', 
                    margin:'auto',
                }}
                anchorReference="anchorPosition"
                anchorPosition={{top:window.innerHeight/2, left:window.innerWidth/2}}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >

                <Grid 
                    container 
                    style={{
                        background: 'rgba(0,0,0,0.95)', 
                        color: 'white', 
                        alignItems:'center', 
                        borderRadius:'20px',
                        margin:'auto',
                        padding:'20px',
                        height:'60vh',
                        width:window.innerWidth<1100?'75vw':'auto',
                        justifyContent:'center',
                        alignItems:'center',
                        overflowY:'scroll',
                        overflowX:'hidden'
                    }}
                >            
                     {transactionStates[currentTransactionPhase] === transactionStates['waitingForPurchaseRequest'] &&
                        selectQuantityAndBuyScreen()
                     }
                     {transactionStates[currentTransactionPhase] > transactionStates['waitingForPurchaseRequest'] && transactionStates[currentTransactionPhase] < transactionStates['transactionComplete'] &&
                        <Grid container style={{fontSize:'24pt', justifyContent: 'center', alignItems: 'center' }}>
                            {transactionMessage}
                        </Grid>
                     }
                     {transactionStates[currentTransactionPhase] === transactionStates['transactionFailed'] &&
                        <Grid container style={{fontSize:'24pt', justifyContent: 'center', alignItems: 'center' }}>
                            {transactionMessage}
                        </Grid>
                     }
                     {transactionStates[currentTransactionPhase] === transactionStates['transactionComplete'] &&
                        showLootRewards()
                     }
                </Grid>
            </StyledPopover>
        </Backdrop>
    )
    



  })


  const useStyles = makeStyles((theme) => ({


    rewardsContainer: {
        overflowX: "hidden",
        overflowY: "auto",
        height:'auto',
        width: 'auto',
        padding:'25px',
        background: '#080c0d',
        boxShadow: "none",
        textAlign:"center",
        justifyContent: "center",
        color:'white'
    },

    popContainer: {
        overflowX: "hidden",
        overflowY: "hidden",
        maxWidth: 800,
        width: 'auto',
        height: "auto",
        padding:'10px',
        margin:'0px',
        backgroundColor:'#181c24',
        color:'white',
    },

    slotsContainer: {
        display: "flex",
        overflowY: "hidden",
        overflowX: "hidden",
        width: 'auto',
        height: "auto",
        padding:'10px',
        alignItems: "center",
        justifyContent: "center"
    },

    loaderContainer: {
        pointerEvents: "none",
        backgroundColor: "#080c0d",
        width: "auto",
        height: "auto",
        margin: 20
    },
    
    rewardItem: {
        width: 250,
        height: "auto",
        margin: 20
    },

    numFieldProp: {
        height: '36px',
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    numFieldLabel:{
        color: "white"
    },

}))



export default BuyAndMintPopover