import React, { } from 'react'
import { makeStyles, Grid, TextField } from '@material-ui/core';

const CreatureToolbar = ({setNameReturn, setSeasonReturn, setEditionReturn}) => {
    
    const styles = useStyles()

    return (
        <Grid container spacing = {4} justifyContent = "center" alignItems = "flex-end" style={{color:'white'}}>
            <Grid item>
                <TextField
                label = "Pepe Name"
                variant = "outlined"
                id="standard-textarea"
                size="small"
                className = {`${styles.textFieldProp} ${styles.longTextField}`}
                InputProps = {{className: styles.textFieldProp}}
                InputLabelProps = {{className: styles.textFieldLabel}}
                fullWidth
                onChange = {(e) => setNameReturn(e.target.value)}
                />
            </Grid>

            {/* <Grid item>
                <TextField
                label = "Season"
                variant = "outlined"
                type = "number"
                id="standard-textarea"
                size="small"
                className = {styles.textFieldProp}
                InputProps = {{className: styles.textFieldProp}}
                InputLabelProps = {{className: styles.textFieldLabel}}
                fullWidth
                style = {{width: "12ch"}}
                onChange = {(e) => setSeasonReturn(e.target.value)}
                />
            </Grid>

            <Grid item>
                <TextField
                label = "Edition"
                variant = "outlined"
                type = "number"
                id="standard-textarea"
                size="small"
                className = {styles.textFieldProp}
                InputProps = {{className: styles.textFieldProp}}
                InputLabelProps = {{className: styles.textFieldLabel}}
                fullWidth
                style = {{width: "12ch"}}
                onChange = {(e) => setEditionReturn(e.target.value)}
                />
            </Grid> */}
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({

    field: {
        padding: 0,
        margin: 0
    },

    textFieldProp: {
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    textFieldLabel:{
        color: "white"
    },

    longTextField: {
        width: "24ch"
    }

  }));

export default CreatureToolbar
