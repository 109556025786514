import React, { useState, useEffect, useRef, useReducer } from 'react'
import { makeStyles, Grid, Typography, Button, TextField, Divider, CircularProgress, Zoom} from '@material-ui/core';
import styled, {keyframes, css} from 'styled-components';
import { rarityColors } from "../../helpers/GlobalStore"
import CurrencySymbolProvider from '../../components/CurrencySymbolLoader';
import StyledCardContainer from '../StyledCardContainer';
import {useStore} from '../../helpers/GlobalStore'
import { Demodal } from 'demodal'
import WhatsInsidePopover from './WhatsInsidePopover';
import SvgButton from '../SvgButton'
import { useContractReducer } from '../../helpers/ContractReducer';
import BuyAndMintPopover from './BuyAndMintPopover';
import { useAoeApiClient } from '../../helpers/AoeApiClient';
import { useAuthReducer } from '../../helpers/AuthReducer';
import ArtLoader from '../../assets/CardArtProvider'


const radiate = keyframes`

    from {
        filter: brightness(1.3) drop-shadow(0px 0px 8px #00ff00);

    }
    to {
        filter: brightness(1)  drop-shadow(0px 0px 1px #00ff00);

    }
`

const radiateAnimation = props => 
css`
    ${radiate} infinite alternate-reverse 2000ms;

`
  

const StyledShopCard = styled(Grid)`
    height:300px;
    width:215px;
    background-image: ${props => `url(${props.theme.imgName})` || `none`};
    background-size: 100% 100%;
    transition: all 0.2s ease-in-out;
    cursor: ${(props) => props.theme.hover? 'pointer': 'default'};
  
    &:hover{
        animation: ${(props) => props.theme.hover? radiateAnimation : 'none'};
        transform: ${(props) => props.theme.hover? 'scale(1.2)': 'none'};
    }
    &::after{
        
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        opacity: 0;
    }


        
      
    }
`

const isNullUndefinedOrEmpty = (obj) => {
    return( obj === undefined || obj === null || obj === ''  )
} 

const ChowBundleCard = ({chowBundle, chainId}) => {
    const { getArt } = ArtLoader();
    const { getCurrencySymbol } = CurrencySymbolProvider();
    const [chowBundleState, dispatch] = useReducer(chowBundleReducer, chowBundle)
    const {paymentManagerState} = useContractReducer()
    const {getChowBundleDetails} = useAoeApiClient()
    const {authState} = useAuthReducer()

    const {bundleId, displayName, bundleName, basePrice, salePrice, disabled, chowInBundle} = chowBundleState;

    

    function chowBundleReducer(chowBundleState, action) {
        switch (action.type) {
            case 'clearField': {
                return {
                    ...chowBundleState,
                    disabled: true
                };
            }
            case 'disableInteraction': {
                return {
                    ...chowBundleState,
                    disabled: true
                };
            }
            case 'updateBundleState':

                return{
                    ...chowBundleState,
                  //  ...action.payload.updatedBundleDetails,
                    basePrice: action.payload.updatedBundleDetails.basePrice,
                    salePrice: action.payload.updatedBundleDetails.salePrice,
                    displayName: action.payload.updatedBundleDetails.displayName,
                    type: action.payload.updatedBundleDetails.type,
                    chowInBundle: action.payload.updatedBundleDetails.chowInBundle,
                    disabled: false
                }
            default:
                return
        }
    }


    const loadUpdatedBundleDetails = async () => {   

        const updatedBundleDetails = await getChowBundleDetails(bundleId)
        
        await dispatch({ 
            type: 'updateBundleState', 
            payload: {
                updatedBundleDetails: updatedBundleDetails
            }
        });

    }

    const handleBundleUpdate = async() => {
            await loadUpdatedBundleDetails()
    }
  
    useEffect(()=>{
        if(!isNullUndefinedOrEmpty(paymentManagerState) && !isNullUndefinedOrEmpty(paymentManagerState.paymentManagerState)){
            handleBundleUpdate()
        }
        else{
            dispatch({type:'clearField'})
        }
    },[paymentManagerState])


    useEffect(()=>{
        if(isNullUndefinedOrEmpty(paymentManagerState) || isNullUndefinedOrEmpty(paymentManagerState.paymentManagerState)){
            loadUpdatedBundleDetails()
        }
    },[])
    
    const handleCardClick = async  () => {
        let currencySymbol = getCurrencySymbol(chainId)
        await Demodal.open(BuyAndMintPopover, {pack: chowBundleState, currencySymbol: currencySymbol, cardUpdateDispatch: dispatch})
    }

    return(
        <Grid container style={{display:'flex', columnGap:'100%', flexShrink:10, width: 215}} >
            <Grid 
                item 
                style={{
                    cursor: "pointer",
                    width: 215,
                    height: 300,
                    marginBottom:15,
                }}
            >
               <StyledShopCard
                    theme={{
                        hover: (authState && authState.userAuthorized && !disabled),
                        imgName: getArt(bundleName)
                    }}
                    onClick = {() => authState && authState.userAuthorized && !disabled ? handleCardClick(chowBundleState):{}}
                />
            </Grid>

            <Grid item style={{width: 215}}>
                <Typography variant = "h5" style = {{color: `white`, textAlign: "center", fontWeight: "bold"}}>
                    {displayName}
                </Typography>
            </Grid>            
            <Grid item >

                <Grid container style={{justifyContent:'center', alignItems:'center', marginBottom:'20px', width:215}}>
                    <Grid item >
                        { getCurrencySymbol(chainId) }
                    </Grid>
                    {salePrice > 0 &&
                    <Grid item style = {{marginLeft: 5}}>
                        <Typography 
                            variant = "h6" 
                            style = {{
                                color: "white",
                                fontSize:'20pt',
                                fontWeight:'bold',
                                fontStyle:'line-through'
                            }}
                        >
                            { basePrice }
                        </Typography>
                     </Grid>
                    }
                    <Grid item style = {{marginLeft: 5}}>
                        <Typography 
                            variant = "h6" 
                            style = {{
                                color: "white",
                                fontSize:'20pt',
                                fontWeight:'bold'
                            }}
                        >
                            {salePrice > 0? 
                                salePrice:
                                basePrice
                            }
                        </Typography>
                    </Grid>
                   
                </Grid>
            </Grid>       
           
        </Grid>
    )
}

export default ChowBundleCard;



