import ServerUtil from './ServerUtil'

//Music Constants
export const MAIN_BG_MUSIC = 'main-bg-music';
export const SEARCHING_MUSIC = 'searching-music';
export const HIGH_CARD_MUSIC = 'high-card-music';
export const VICTORY_MUSIC = 'victory-music';
export const DEFEAT_MUSIC = 'defeat-music';

/*
    Handles audio management, plays appropriate music at different times
*/
const AudioManager = (scene) => {

    const ALL_AUDIO = [
        MAIN_BG_MUSIC,
        SEARCHING_MUSIC,
        HIGH_CARD_MUSIC,
        VICTORY_MUSIC,
        DEFEAT_MUSIC
    ];

    //Volumes
    const VOLUME_MUTED = 0.0;
    const VOLUME_LOW = 0.3;
    const VOLUME_MEDIUM = 0.6;
    const VOLUME_HIGH = 1.0;

    //Prevents music from pausing when window loses focus
    scene.sys.game.sound.pauseOnBlur = false;

    //Keep track of what song is active/playing
    var activeMusic = null;
    var activeVolume = null;
    var globalVolume = 1.0;

    //Add musics we will be managing
    var backgroundMusic = scene.sys.game.sound.add(MAIN_BG_MUSIC);
    backgroundMusic.loop = true; //does what you think
    var searchingMusic = scene.sys.game.sound.add(SEARCHING_MUSIC);
    var highCardMusic = scene.sys.game.sound.add(HIGH_CARD_MUSIC);
    var victoryMusic = scene.sys.game.sound.add(VICTORY_MUSIC);
    var defeatMusic = scene.sys.game.sound.add(DEFEAT_MUSIC);

    function setVolume(VOLUME_LEVEL){
        //Set whatever is actively playing
        activeMusic && activeMusic.setVolume && activeMusic.setVolume(VOLUME_LEVEL);

        //Set the volume of all other things so that if we switch to playing them, it'll automatically be at the right volume
        for (var i = 0; i < ALL_AUDIO.length; i++) {
            var audio = ALL_AUDIO[i];
            audio && audio.setVolume && audio.setVolume(VOLUME_LEVEL);
        }
    }

    function stopActiveMusic(){
        activeMusic && activeMusic.stop && activeMusic.stop();
    }

    function playActiveMusic(){
        activeMusic && activeMusic.play && activeMusic.play();
    }

    function setVolumeOff(){
        activeVolume = VOLUME_MUTED;
        setVolume(VOLUME_MUTED);
    }
    function setVolumeLow(){
        activeVolume = VOLUME_LOW;
        setVolume(VOLUME_LOW);
    }
    function setVolumeMedium(){
        activeVolume = VOLUME_MEDIUM;
        setVolume(VOLUME_MEDIUM);
    }
    function setVolumeHigh(){
        activeVolume = VOLUME_HIGH;
        setVolume(VOLUME_HIGH);
    }

    function playMusic(MUSIC){
        stopActiveMusic();

        //Switch to the specified music as the "active music"
        switch (MUSIC) {
            case MAIN_BG_MUSIC:
                activeMusic = backgroundMusic;
                break;
            case SEARCHING_MUSIC:
                activeMusic = searchingMusic;
                break;
            case HIGH_CARD_MUSIC:
                activeMusic = highCardMusic;
                break;
            case VICTORY_MUSIC:
                activeMusic = victoryMusic;
                break;
            case DEFEAT_MUSIC:
                activeMusic = defeatMusic;
                break;
            default:
                console.warn("Unexpected music key selected to play: " + MUSIC);
                activeMusic = backgroundMusic;
                break;
        }

        setVolume(activeVolume);
        playActiveMusic();
    }

    return {
        setVolumeOff: setVolumeOff,
        setVolumeLow: setVolumeLow,
        setVolumeMedium: setVolumeMedium,
        setVolumeHigh: setVolumeHigh,
        playMusic: playMusic,
        stopMusic: stopActiveMusic
    };

}

export default AudioManager;
