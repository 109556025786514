import { useCallback, useEffect, useState } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
// import {
//     UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
//     WalletConnectConnector,
// } from '@web3-react/walletconnect-connector'
import { simpleRpcProvider, getLibrary, requestNetworkSwitch, getSupportedChainId } from './web3ReactUtil'
import { ethers } from 'ethers'
import CONFIG from '../config/config'
import { useStore } from './GlobalStore'
import Web3 from "web3";
import { useAoeApiClient } from './AoeApiClient'
import detectEthereumProvider from '@metamask/detect-provider';
import { Demodal } from 'demodal'
import SupportedChainModal from '../components/support-chain-modal/SupportedChainModal'
import ConnectModal from '../components/connect-modal/ConnectModal'
import { useContractReducer } from './ContractReducer'
import { useAuthReducer } from './AuthReducer'

const INJECTED_CONNECTOR_ID = 0;
const injectedConnector = new InjectedConnector({ supportedChainIds: CONFIG.SUPPORTED_CHAINS.values().map(chain=> {return( parseInt(chain.chainId))}) })
var connnectorObjectArray = [injectedConnector]

//needed to be added globally in file since react states misbehave
var userSelectedChain = 0;

var userLoginRequester = ''
var isUserLoggingIn = false

// const walletconnect = new WalletConnectProvider({
//     rpc: {
//         [parseInt(CONFIG.CHAIN_ID)]: CONFIG.ETH_NODE },
//         pollingInterval: 12000,
//         qrcode: true,
// })
var changeChainRequestOrigin = ''

const isNullUndefinedOrEmpty = (obj) => {
    return( obj === undefined || obj === null || obj === ''  )
} 

const setupNetwork = async ( {userSelectedChain, externalProvider}) => {
    const provider = externalProvider || window.ethereum
    const chainId = parseInt(externalProvider.chainId) 

    if (!CONFIG.SUPPORTED_CHAINS.values().some(chain=> chain.chainId === userSelectedChain)) {
      console.error('Invalid chain id')
      return false
    }

    if (provider) {
        const selectedChain = CONFIG.SUPPORTED_CHAINS.get(userSelectedChain+'')
        try {
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${selectedChain.hexChainId}` }],
            })
            return true
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: `0x${selectedChain.hexChainId}`,
                            chainName: `${selectedChain.name}`,
                            nativeCurrency: selectedChain.nativeCurrency,
                            rpcUrls: selectedChain.node,
                            blockExplorerUrls: [selectedChain.browser],
                        }, ],
                    })
                    return true
            } catch (error) {
                console.error('Failed to setup the network in Metamask:', error)
                return false
            }
            }
            return false
        }
    } else {
      console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
      return false
    }
  }


const useWallet = () => {
    const { chainId, activate, deactivate, account, library, setError, error } = useWeb3React()
    const { webToken, setCurrentChainId, currentChainId, walletType, setWalletType, setUserAddress, userAddress, setUserAccountChanged, userAuthExists, setConnectionText} = useStore()
    const { signIn, signOut, isAuthorized } = useAoeApiClient()
    const { dispatchPmUpdate } = useContractReducer()
    const { authState, dispatchAuthUpdate } = useAuthReducer()

    var localProvider = null;


    useEffect(()=>{
        Promise.resolve(checkAuth())
    },[])

    useEffect(()=>{

        if(!isNullUndefinedOrEmpty(authState) && authState.authChecked){

            if(!isNullUndefinedOrEmpty(chainId) && !isNullUndefinedOrEmpty(account)){
                if((authState.authChecked && isNullUndefinedOrEmpty(authState.authToken)) ||
                    (account.toString().toLowerCase() !== authState.userAddress.toString().toLowerCase()) ||
                    (chainId !== authState.chainId)
                ){
                    handleSignIn();
                }
            }
            else{
                if(isNullUndefinedOrEmpty(authState.authToken)){
                    signOut();
                }
                else{
                    //console.log(`jwt was not null`)
                    //console.log(authState.authToken)
                }
            }
        }
        else{
            if(CONFIG.SUPPORTED_CHAINS.values().some(chain=> ( parseInt(chain.chainId) === parseInt(chainId)))){
                handleSignIn()
            }
        }
       
    }, [authState, chainId, account])



    const checkAuth = async () => {
        var authResp = await isAuthorized()
        return authResp
    }
 
    const handleSignIn = async () =>{
        var errState = 0;
        if(!isNullUndefinedOrEmpty(chainId) && !isNullUndefinedOrEmpty(account)){

            if(!isNullUndefinedOrEmpty(library)){
                const signer = await library.getSigner(account)
                
                try{
                    errState = await signIn(account, signer).then((response) => {
                        return response
                    }) 
                    disableWalletListeners(library.provider)
                    enableWalletListeners(library.provider)
                }
                catch(err)  {
                    console.log(err)
                } 
            }
        }

    }

    const login = useCallback( async (connectorId) => {
       
        const localStorage = window.localStorage
        var localChainId = 0;
        var localConnectorId; 
        var testLib = await getLibrary(window.ethereum)
        if(userLoginRequester === 'ACCOUNT_CHANGE'){
            localConnectorId = parseInt(localStorage.getItem('userWalletType'));
        }
        else{
            localConnectorId =  await Demodal.open(ConnectModal, {dialogueText: "Select Wallet"});
        }
            
        if(isNullUndefinedOrEmpty(localConnectorId)){
            return;
        }
        else{
            localStorage.setItem('userWalletType', localConnectorId.toString())
        }

        if(localConnectorId === INJECTED_CONNECTOR_ID){
            localProvider = await detectEthereumProvider({
                mustBeMetaMask: false
            })
            if(!localProvider){
                console.warn('No supported wallets found')
                return;
            }

            localChainId = await localProvider.request({ method: 'eth_chainId' }).then(async  (res) => {
                setCurrentChainId(res)
                return(res)
            })
        }

        const connector = connnectorObjectArray[parseInt(localConnectorId)]
        
        if(isNullUndefinedOrEmpty(connector)){
            console.log('Unable to find connector: The connector config is wrong');
            return;
        }
        
        await activate(connector, async (error) => {
            setError(error)
            if (error instanceof UnsupportedChainIdError) {

                const provider = await connector.getProvider()

                const proposedChain = await Demodal.open(SupportedChainModal, {dialogueText:'Select Chain'})
                
                
                const chainConfigExists = await setupNetwork({userSelectedChain: proposedChain, externalProvider: provider})
                if (chainConfigExists) {
                    
                    await activate(connector)          
                    setCurrentChainId(userSelectedChain)  
                }
                else{
                    if(userLoginRequester !== 'USER_CHANGED_CHAIN'){
                        logout()
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            } else {
                console.log(error)
                if (error instanceof NoEthereumProviderError) {
                    console.log('Provider Error: No provider was found')
                } else if (
                    error instanceof UserRejectedRequestErrorInjected 
                    //   || error instanceof UserRejectedRequestErrorWalletConnect
                ) {
                    // if (connector instanceof WalletConnectConnector) {
                    //     const walletConnector = connector
                    //     walletConnector.walletConnectProvider = null
                    // }
                    console.log('Authorization Error: Please authorize to access your account')
                } else {

                    console.log(error)
                }
                console.log(error)
            }
        })

        //     else if( localConnectorId === WALLET_CONNECT_CONNECTOR_ID){
        //         const newWalletConnectConnector = new WalletConnectProvider({
        //             rpc: {
        //                 [parseInt(CONFIG.CHAIN_ID)]: CONFIG.ETH_NODE },
        //                 pollingInterval: 12000,
        //                 qrcode: true,
        //         })
                
        //         try{

        //             await newWalletConnectConnector.enable().then((userAddr)=>{
        //                 setUserAddress(userAddr[0]);
        //                 newWalletConnectConnector.wc.on(
        //                     'disconnect',  
        //                     onDisconnectCallback.bind(this)
        //                 );
        //                 connnectorObjectArray[WALLET_CONNECT_CONNECTOR_ID] = newWalletConnectConnector;
        //                 return userAddr[0];
        //             });
                    
        //         }
        //         catch(err){
        //             console.log(err.message)
        //             if(err.message === 'User closed modal'){
        //                 await newWalletConnectConnector.qrcodeModal.close();
        //                 await newWalletConnectConnector.close();
        //                 newWalletConnectConnector = null;
        //             }
        //         }
        //     }
        // } 

    }, [activate, setError])




    const logout = useCallback( async () => {
        var localWalletType = walletType;
        const localStorage = window.localStorage

        setUserAddress(undefined)
        setCurrentChainId(undefined)
       
       
        if(isNullUndefinedOrEmpty(localWalletType)){
            localWalletType = localStorage.getItem('userWalletType')
        }

        if(parseInt(localWalletType) === INJECTED_CONNECTOR_ID){
            
            const provider = await connnectorObjectArray[INJECTED_CONNECTOR_ID].getProvider()
            if(!isNullUndefinedOrEmpty(provider))
              //  await disableWalletListeners(provider)

            await connnectorObjectArray[INJECTED_CONNECTOR_ID].handleClose('0', 'User Signed Out')
            await signOut()
    //        await deactivate()
            
        }
        else if(parseInt(localWalletType) === WALLET_CONNECT_CONNECTOR_ID){
        // This localStorage key is set by @web3-react/walletconnect-connector
            try{
                await connnectorObjectArray[WALLET_CONNECT_CONNECTOR_ID].disconnect()
            }
            catch(error){
                console.log(error.message)
            }
            try{
               await connnectorObjectArray[WALLET_CONNECT_CONNECTOR_ID].close()
            }
            catch(error){
                console.log(error.message)
            }
            signOut()
            connnectorObjectArray[WALLET_CONNECT_CONNECTOR_ID].walletConnectProvider = null
        }
      
    }, [deactivate])

    

    const getCurrentChainId = async () => {
      
        if(!window.ethereum){
            return
        }
        const provider = window.ethereum
        try{
          await provider.request({ method: 'eth_chainId' }).then(async  (res) => {
              setCurrentChainId(res)
              return(res)
          })
        }
        catch(err){
          console.log(err.message)
        }
        return(null)
    }


    const hasUserAccountChanged = async (userAddress) => {
        const provider = await detectEthereumProvider({
            mustBeMetaMask: false
        })


        const ethAccounts = await provider.request({ method: 'eth_accounts' }).then(async  (res) => {
            return(res)
        }).catch(error=>{
            console.log(error.message)
        })
        if(userAddress && userAddress !== ethAccounts[0]){
            return true;
        }
        else{
            return(false)
        }
    }
    const checkForChainChange = (detectedChain) => {
        if(detectedChain !== authState.chainId)
        {
            console.log('DO SOMETHING')
        }
    }

    const addDisconnectEventListener = async (handleUserWalletDisconnect) => {
        const localStorage = window.localStorage;
        var localWalletType = walletType;

        if(localWalletType === undefined || localWalletType === null){
            localWalletType = parseInt(localStorage.getItem('userWalletType'))
        }
        
        // if(localWalletType === WALLET_CONNECT_CONNECTOR_ID){
        //     connnectorObjectArray[localWalletType].wc.on(
        //         'disconnect',  
        //         handleUserWalletDisconnect.bind(this)
        //     );
        // }
    }

    const disableWalletListeners = (provider) => {
        provider?.removeAllListeners(["networkChanged"])
        provider?.removeAllListeners(["chainChanged"])
        provider?.removeAllListeners(["accountsChanged"])
    }
    
    const enableWalletListeners =  (provider) => {
        provider?.on('chainChanged',  async () => {
            dispatchPmUpdate({type:'invalidatePM', payload:null})
            if(!CONFIG.SUPPORTED_CHAINS.values().some(chain=> ( `0x${chain.hexChainId}` === provider.chainId))){
                changeChainRequestOrigin = 'USER_CHANGED_CHAIN'
                logout()
            }
            else{
                let lib = await getLibrary(provider);
                let w3Signer = await lib.getSigner(provider.selectedAddress)
                let parsedChain = parseInt(provider.chainId, 16)
                let chainConfig = CONFIG.SUPPORTED_CHAINS.get(parsedChain)

                if(parsedChain !== parseInt(currentChainId, 16)){
                    logout()
                    userLoginRequester = 'ACCOUNT_CHANGE';
                  //  login()
                }
            }
        });
        provider?.on('accountsChanged',  () => {
          logout()
          userLoginRequester = 'ACCOUNT_CHANGE';
          //login()
        });
    }


    return { 
        login, 
        logout, 
        getCurrentChainId, 
        hasUserAccountChanged, 
        addDisconnectEventListener, 
        disableWalletListeners, 
        enableWalletListeners
    }
}

export default useWallet