export const mediaTokenAddresses = {
    1: '0x1d5B9AA1A7cda0Bc1077C82Fb96c58973fc8b953',
    5: '0xA05b67531f95B75b1c0b42e96d0a7e0048DC9aD9',
    42: '',
    56: '',
    97: '0xb0CD784ec30E09A20684D578F77b578aC90C6d46',
    137: '',
    80001: '',
}

export const paymentManagerAddresses = {
    1: '0x97f5800D848F2CBbac60cC84781efB8CbfcE8454',
    5: '0xF49D36364E6Cc417b295d0574e8376FEB86E757D',
    42: '',
    56: '',
    97: '0x29BcAFE2CFAe559f93FFb3d2D24B65a3Ba47Ea4A',
    137: '',
    80001: '',
}

export const winningsManagerAddresses = {
    1: '0x97f5800D848F2CBbac60cC84781efB8CbfcE8454',
    5: '0xF49D36364E6Cc417b295d0574e8376FEB86E757D',
    42: '',
    56: '',
    97: '0x29BcAFE2CFAe559f93FFb3d2D24B65a3Ba47Ea4A',
    137: '',
    80001: '',
}

export const claimManagerAddresses = {
    1: '',
    5: '0xF9f3a222507E71fcC251C2bE64d6c489Cc700C1C',
    42: '',
    56: '',
    97: '',
    137: '',
    80001: '',
}

export const promoManagerAddresses = {
    1: '0x258AB050A5f5F5eaB96D2a7A0dDaD4d3d6f3fED9',
    5: '0xb9fd7A3f1A83d19Aee03223De1b9E4d3beF0ccC4',
    42: '',
    56: '',
    97: '0x3F6164dE0a1aE737dF58D4082CfaD7805794A365',
    137: '',
    80001: '',
}