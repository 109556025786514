import React from "react";
import {  Popover, Grid, Backdrop } from '@material-ui/core';
import WalletConfig from "./walletConfig";
import WalletButton from "./WalletButton";
import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import { Zoom } from "@material-ui/core";
import styled, {keyframes, css} from "styled-components";


const connectmModalRef = React.createRef()

const Transition = React.forwardRef(function Transition(props, connectmModalRef) {
    return <Zoom  ref={connectmModalRef} {...props} />
  })

const radiate = keyframes`
  from {
      filter: brightness(1.3) drop-shadow(0px 0px 4px rgba(0,159,200,.5));
  }
  to {
      filter: brightness(1)  drop-shadow(0px 0px 1px rgba(0,159,200,.1));
  }
`

const radiateAnimation = props => 
css`
  ${radiate} infinite alternate-reverse 2000ms;
`

const StyledPopover = styled(Popover)`
    background-size: 100% 100%;
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(0px 5px 4px black);
    animation: ${ radiateAnimation };
  }
`



const ConnectModal = Demodal.create(({dialogueText}) => {
   
    const connectModal = useModal()

  
    const resolve = value => () => {
      connectModal.resolve(value)
      connectModal.close()
    }

    return(
        <Backdrop onClose={()=>connectModal.close()} {...muiDialog(connectModal)} style={{backgroundColor:'rgba(0,0,0,.8)', zIndex: 700}}>
        <StyledPopover
            id={'connect-modal-dialogue'} 
            ref={connectmModalRef}
            {...muiDialog(connectModal)}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                    },
            }}

            style={{
            
                filter:`drop-shadow( 0px 0px 25px rgba(0,0,0, 1))`, 
                justifyContent:'center', 
                alignItems:'center', 
                color: 'white', 
                alignItems:'center', 
                margin:'auto',
            }}
            anchorReference="anchorPosition"
            anchorPosition={{top:window.innerHeight/2, left:window.innerWidth/2}}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
        >
    
            <Grid 
                container 
                style={{
                    background: 'rgba(0,0,0,1)', 
                    color: 'white', 
                    justifyContent:'center', 
                    alignItems:'center', 
                    height:'auto', 
                    width:'100%', 
                    borderRadius:'12px',
                    overflow:'hidden'
                }}
                classes = {{paper: "" }}
            >
                    <Grid item style={{color:'white', fontWeight:'bold', fontSize:'5vh', marginBottom:'10px', textAlign:'center'}}> 
                        {dialogueText} 
                    </Grid>
                {
                    WalletConfig.map((walletType) =>
                        <Grid item xs={12}  key={walletType.title}>
                            <WalletButton 
                                onSelect={resolve(walletType.connectorType)} 
                                walletConfig={walletType}
                                value={walletType.connectorType} 
                            />
                        </Grid>

                    )
                }
            </Grid>
        </StyledPopover>
        </Backdrop>
    )
})


export default ConnectModal