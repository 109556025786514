
/*
    position: {
        x: x_value, 
        y: y_value, 
        z: depth
    }
    //{x: slot.x, y: slot.y, z: 10}
*/
const EnemyBanner = (scene, bannerArt, scale, position, cardScale) => {

    const bannerId = 'enemy_banner';

    const MAX_NAME_LENGTH = 20;

    //add the banner background art
    var bannerArtImage = scene.add.image(position.x, position.y, bannerArt)
        .setDepth(position.z)
        .setOrigin(0.5)
        .setScale(scale);

    var bannerWidth = bannerArtImage.width * scale;
    var bannerHeight = bannerArtImage.height * scale ;

    var leftEdgeOfBanner = position.x - bannerWidth/2;
    var topEdgeOfBanner = position.y - bannerHeight/2;

    //Put in health/energy indicators
    const healthLocationX = leftEdgeOfBanner + bannerWidth*0.95;
    const healthLocationY = topEdgeOfBanner + bannerHeight*0.4;

    var healthFrame = scene.add.image(healthLocationX, healthLocationY, 'banner-health-value-frame')
        .setDepth(bannerArtImage.depth + 1).setOrigin(0.5).setScale(0.5 * cardScale);

    var healthLabel = scene.add.bitmapText(healthLocationX-2, healthLocationY-6, 'gothic', "XX", 40 * cardScale).setOrigin(0.5).setDepth(healthFrame.depth + 1);

    //Put in an empty enemy name until it is set later
    const nameLocationX = leftEdgeOfBanner + bannerWidth*0.16;
    const nameLocationY = topEdgeOfBanner + bannerHeight*0.15;
    var nameLabel = scene.add.bitmapText(nameLocationX, nameLocationY, 'gothic', "", 40 * cardScale).setOrigin(0.0).setDepth(healthFrame.depth + 1);

    //actually store the values in the label object:
    healthLabel.healthValue = 10;

    //Forfeit capture
    var forfeited = false;

    function isDead(){
        return (healthLabel.healthValue <= 0 || forfeited);
    }

    function setForfeited(){
        forfeited = true;
    }

    function setHealth(health){
        healthLabel.healthValue = health;

        //Update the health text
        var healthLabelStr = healthLabel.healthValue;
        healthLabel.setText(healthLabelStr);
    }

    function getHealthFramePosition(){
        return {x: healthLocationX, y: healthLocationY};
    }

    function setName(nameString) {
        nameLabel.setText(nameString.substring(0, MAX_NAME_LENGTH));
    }

    return {
        bannerId: bannerId,
        setName: setName,
        isDead: isDead,
        setForfeited: setForfeited,
        setHealth: setHealth,
        getHealthFramePosition: getHealthFramePosition
    };

}

export default EnemyBanner;
