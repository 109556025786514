import React from "react";
import timelineEntries from './timeLineEntries.json'
import { Parallax } from "react-scroll-parallax";

const TimelineItem = ({ data, speedMult }) => (

            <Parallax
                className="timeline-item"
                speed={5 * speedMult}
                key={speedMult}
                opacity={[.15, 1, [0,0,1,1.5]]}
                shouldAlwaysCompleteAnimation={true}
            >
            <div className="timeline-item-content" style={{filter:`drop-shadow(0px 0px 6px ${data.category.color})`}}>
                <span className="tag" style={{ background: data.category.color }}>
                    {data.category.tag}
                </span>
                <time>{data.date}</time>
                <p style={{fontSize:'16pt', textAlign:'left', fontFamily: 'Roboto'}}>
                    {data.text}
                </p>
                {data.link && (
                    <a
                        href={data.link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {data.link.text}
                    </a>
                )}
                <span className="circle" />
            </div>
            </Parallax>
    
   
);

export const Timeline = ({}) =>(

    timelineEntries.length > 0 && (
        <div className="timeline-container">
            {timelineEntries.map((data, idx) => (
         
                <TimelineItem data={data} key={idx} speedMult={idx}/>
     
            ))}
        </div>
    )

)
