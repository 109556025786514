import React, { createContext, useContext } from "react";
import {useStore} from "./GlobalStore"
import {  getUserAddress, 
          getMediaTokenContract, 
          getPaymentManagerContract,
          getClaimManagerContract,
          getWinningsManagerContract,
          getPromoManagerContract,
          getProvider
         } from '../contracts/useContract';
import { useAuthReducer } from "./AuthReducer";

export const ContractAPIContext = createContext();

export const ContractAPIProvider = ({children}) => {

  const { ZERO_ADDRESS,
          mediaToken, setMediaToken,
          paymentManager, setPaymentManager,
          promoManager, setPromoManager,
          userAddress, setUserAddress
        } = useStore();

  const {authState} = useAuthReducer();

  const getWeb3Provider = async () => {
    return await getProvider()
    .then(res => {
      return res
    })
    .catch(err => {
      console.error(err)
      return null
    })
  }

  const initMetamask = async () => {
    if (userAddress === undefined || userAddress === null || userAddress === ZERO_ADDRESS){
      
      return await getUserAddress().then((res) => {
        setUserAddress(res.uAddr[0]);
        return res
      })
      .catch(error => {
        console.error(error);
        return undefined;
      });
    }

    else return userAddress
  }

  const truncateAddress = () => {
    

    if (authState && authState.userAddress)
        return  authState.userAddress.substring(0,6) + "..." + authState.userAddress.substring(authState.userAddress.length - 4, authState.userAddress.length);
    else{
      return "Connect"
    }
  }


  const initMediaToken = async () => {
    if (mediaToken === null || mediaToken === undefined){

      return await getMediaTokenContract().then((res) => {
          setMediaToken(res.contract); 
          return res.contract;
      })
      .catch(error => {
        console.error(error);
        return undefined;
      });

    }

    else return mediaToken;
  }

  const getPaymentManager = async () => {
    return await getPaymentManagerContract().then((res) => {
      setPaymentManager(res.contract);
      return res.contract
    })
    .catch(error => {
      console.error(error)
      return undefined;
    });
  }
  
  const getClaimManager = async () => {
      return await getClaimManagerContract().then((res) => {
        return res.contract;
      })
      .catch(error => {
        console.error(error);
        return undefined;
      });
  }

  const getWinningsManager = async () => {
      return await getWinningsManagerContract().then((res) => {
        return res.contract;
      })
      .catch(error => {
        console.error(error);
        return undefined;
      });
  }

  const getPromoManager = async () => {
    return await getPromoManagerContract().then((res) => {
      setPromoManager(res.contract);
      return res.contract;
    })
    .catch(error => {
      console.error(error);
      return undefined;
    });
  }

  return (
      <ContractAPIContext.Provider value = {
      {
        initMediaToken,
        initMetamask,
        truncateAddress,
        getPaymentManager,
        getClaimManager,
        getPromoManager,
        getWeb3Provider,
        getWinningsManager
      }
      }
      >
  
        {children}
  
      </ContractAPIContext.Provider>
    )
}

export const useContractAPI = () => useContext(ContractAPIContext);
