//import Phaser from "phaser"

/*
    position: {
        x: x_value, 
        y: y_value, 
        z: depth
    }
    //{x: slot.x, y: slot.y, z: 10}

    trimArt: a string representing the art asset that sits as the backdrop of the art of the gameObject.
        e.g. an outline indicating where a Card would appear once the Slot has an object placed into it.
*/
const Slot = (slotId, scene, trimArt, scale, position) => {
    
    var gameObject;
    var slotPosition = position;

    //add the trim art
    scene.add.image(slotPosition.x, slotPosition.y, trimArt)
        .setDepth(slotPosition.z)
        .setOrigin(0.5)
        .setScale(scale);

    function destroyObject() {
        if (!gameObject)
            return;
        else{
            gameObject.destroy();
            gameObject = null;
        }
    }

    /*
        gameObject: generally assumed to just be a Phaser object, but can be any object
        which has the following methods:
            - destroy()
            - setPosition(x, y)
            - setDepth(z)
    */
    function placeObject(newGameObject) {
        if (!newGameObject || !newGameObject.destroy || !newGameObject.setPosition || !newGameObject.setDepth){
            console.log("Attempted to place null or non-Phaser object in slot");
            return;
        }

        //destroy any existing game object and add the new one
        gameObject && destroyObject();
        gameObject = newGameObject;

        //the trim art is at slotPosition.z, game object should always sit on top of it, so always set it one higher
        gameObject.setPosition(slotPosition.x, slotPosition.y);
        gameObject.setDepth(slotPosition.z + 1); 
    };

    //so that the caller uses this explicitly instead of accidentally SETTING the gameObject directly
    function getObject(){
        return gameObject;
    }

    function isEmpty(){
        return !gameObject;
    }


    return {
        slotId: slotId,
        position: slotPosition,
        placeObject: placeObject,
        getObject: getObject,
        isEmpty: isEmpty,
        destroyObject: destroyObject
    };

}

export default Slot;
