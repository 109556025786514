import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import './App.css';
import { Grid } from '@material-ui/core';
import React from "react";
import { ContractAPIProvider } from './helpers/ContractAPI';
import { AoeApiProvider } from './helpers/AoeApiClient';
import NavBar from './components/NavBar.js';
import InventoryPage from './pages/InventoryPage.js'
import HomePage from './pages/HomePage.js'
import HowHomePage from './pages/HomePageRedesign';
import GamePage from './pages/GamePage.js'
import StorePage from './pages/StorePage.js'
import DecksPage from './pages/DecksPage.js'
import ProfilePage from './pages/ProfilePage';
import LorePage from './pages/LorePage';
import GuidePage from './pages/GuidePage'
import CreditsPage from './pages/CreditsPage';
import { useStore } from './helpers/GlobalStore';
import { ContractReducerProvider } from './helpers/ContractReducer';
import { AuthReducerProvider } from './helpers/AuthReducer';
import { Demodal } from 'demodal'
import { ParallaxProvider } from 'react-scroll-parallax';

const App = () => {

  const {isGameLoaded, gameLoading} = useStore()

  return (
    <Grid id="AppMainGrid" container style={{alignItems:'center', justifyContent:'center', overflowX:'clip'}}>
      <AuthReducerProvider>
        <ContractAPIProvider>
          <ContractReducerProvider>
            <AoeApiProvider>
              <ParallaxProvider>
                <Router>
                  
                  <>
                    {isGameLoaded === false && gameLoading === false &&
                      <Grid item id="AppNavGrid" style={{ height: 65 }}>
                        <NavBar/>
                      </Grid>
                    }
                  </>
                  <Demodal.Container />
                  <Grid item id="AppRouteSwitch" style={{ width:'100vw' }}>
                    <Switch>
                      <Route path="/pepes" component={InventoryPage}/>
                      <Route path="/squads" component={DecksPage} />
                      <Route path="/play" component={GamePage} />
                      {/* <Route path="/store" component={StorePage} /> */}
                      <Route path="/profile" component={ProfilePage} />
                      {/* <Route path="/howto" component={GuidePage} /> */}
                      <Route exact path="/" component={HowHomePage} />
                    </Switch>
                  </Grid>
                </Router>
                </ParallaxProvider>
                
            </AoeApiProvider>
          </ContractReducerProvider>
        </ContractAPIProvider>
      </AuthReducerProvider>
    </Grid>
  )
}

export default App;
