import React from 'react'
import { Grid, Typography, makeStyles, Link } from '@material-ui/core';
import BackgroundManager from '../components/BackgroundImageManager'
import ArtLoader from "../assets/CardArtProvider"

const CreditsPage = () => {

    const styles = useStyles()
    const { getArt } = ArtLoader();

    return (
        <div style={{width:'100vw', marginBottom:'100px'}}>
            <BackgroundManager sourceImage={getArt('LorePageBG')} blur='0px' />

            <Grid justifyContent='center' container style={{maxWidth:'70vw', padding:'auto', margin:'auto', textAlign:'center', paddingTop:'100px'}}>
                <Grid item xs = {12} style={{marginBottom:'15px'}}>
                    <Typography variant = "h2" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Music Credits
                    </Typography>
                </Grid>

                <Grid item xs = {12} style={{marginBottom:'15px', padding:'10px', background:'rgba(0,0,0,.7)'}}>
                    <Typography variant = "h5" className={`${styles.textWhite} ${styles.textCentered}`}>
                        Ale and Anecdotes by Darren Curtis | &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.darrencurtismusic.com/'>
                            https://www.darrencurtismusic.com/
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Music promoted on &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.chosic.com/free-music/all/'>
                            https://www.chosic.com/free-music/all/
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Creative Commons Attribution 3.0 Unported (CC BY 3.0)
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        <Link className={`${styles.textWhite}`} href='https://creativecommons.org/licenses/by/3.0'>
                            https://creativecommons.org/licenses/by/3.0/
                        </Link>
                    </Typography>
                </Grid>

                <Grid item xs = {12} style={{marginBottom:'15px', padding:'10px', background:'rgba(0,0,0,.7)'}}>
                    <Typography variant = "h5" className={`${styles.textWhite} ${styles.textCentered}`}>
                        One Bard Band by Alexander Nakarada | &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.serpentsoundstudios.com'>
                            https://www.serpentsoundstudios.com
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Music promoted by &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.chosic.com/free-music/all/'>
                            https://www.chosic.com/free-music/all/
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Creative Commons CC BY 4.0
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        <Link className={`${styles.textWhite}`} href='https://creativecommons.org/licenses/by/4.0/'>
                            https://creativecommons.org/licenses/by/4.0/
                        </Link>
                    </Typography>
                </Grid>

                <Grid item xs = {12} style={{marginBottom:'15px', padding:'10px', background:'rgba(0,0,0,.7)'}}>
                    <Typography variant = "h5" className={`${styles.textWhite} ${styles.textCentered}`}>
                        Planning by Alexander Nakarada | &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.serpentsoundstudios.com'>
                            https://www.serpentsoundstudios.com
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Music promoted by &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.chosic.com/free-music/all/'>
                            https://www.chosic.com/free-music/all/
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Creative Commons CC BY 4.0
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        <Link className={`${styles.textWhite}`} href='https://creativecommons.org/licenses/by/4.0/'>
                            https://creativecommons.org/licenses/by/4.0/
                        </Link>
                    </Typography>
                </Grid>

                <Grid item xs = {12} style={{marginBottom:'15px', padding:'10px', background:'rgba(0,0,0,.7)'}}>
                    <Typography variant = "h5" className={`${styles.textWhite} ${styles.textCentered}`}>
                        Tavern Loop One by Alexander Nakarada | &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.serpentsoundstudios.com'>
                            https://www.serpentsoundstudios.com
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Music promoted by &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://alexandernakarada.bandcamp.com/'>
                            https://alexandernakarada.bandcamp.com/
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Creative Commons CC BY 4.0
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        <Link className={`${styles.textWhite}`} href='https://creativecommons.org/licenses/by/4.0/'>
                            https://creativecommons.org/licenses/by/4.0/
                        </Link>
                    </Typography>
                </Grid>

                <Grid item xs = {12} style={{marginBottom:'15px', padding:'10px', background:'rgba(0,0,0,.7)'}}>
                    <Typography variant = "h5" className={`${styles.textWhite} ${styles.textCentered}`}>
                        Witch by Damiano Baldoni | &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://soundcloud.com/damiano_baldoni'>
                            https://soundcloud.com/damiano_baldoni
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Music promoted by &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.chosic.com/free-music/all/'>
                            https://www.chosic.com/free-music/all/
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Creative Commons CC BY 4.0
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        <Link className={`${styles.textWhite}`} href='https://creativecommons.org/licenses/by/4.0/'>
                            https://creativecommons.org/licenses/by/4.0/
                        </Link>
                    </Typography>
                </Grid>

                <Grid item xs = {12} style={{marginBottom:'15px', padding:'10px', background:'rgba(0,0,0,.7)'}}>
                    <Typography variant = "h5" className={`${styles.textWhite} ${styles.textCentered}`}>
                        Medieval: The Old Tower Inn by RandomMind | &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.chosic.com/free-music/all/?keyword=RandomMind&artist'>
                            RandomMind Chosic Page
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        Music promoted by &nbsp;
                        <Link className={`${styles.textWhite}`} href='https://www.chosic.com/free-music/all/'>
                            https://www.chosic.com/free-music/all/
                        </Link>
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        CC0 1.0 Universal (CC0 1.0)
                    </Typography>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textCentered}`}>
                        <Link className={`${styles.textWhite}`} href='https://creativecommons.org/publicdomain/zero/1.0/'>
                            https://creativecommons.org/publicdomain/zero/1.0/
                        </Link>
                    </Typography>
                </Grid>

                
               

            </Grid>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({

    textWhite: {
        color: "white",
        width: "auto"

    },

    textCentered: {
        textAlign: "center"
    },
    textLeft: {
        textAlign: "left"
    },

}));

export default CreditsPage;
