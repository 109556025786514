import React from "react";
import styled, {keyframes} from "styled-components";



const fontFlicker = keyframes`

    0% { 
        opacity: 1;
        background-position: 70% 70%;
        filter: drop-shadow(0px 0px 1px black);
    }

    15% { 
        opacity: 1;
        background-position: 10% 30%;
        filter: drop-shadow(0px 0px 2px yellow);
    }

    30% { 
        opacity: 1;
        background-position: 0% 30%;
        filter: drop-shadow(0px 0px 1px black);
    }

    45% { 
        opacity: 1;
        background-position: 30% 40%;
        filter: drop-shadow(0px 0px 1px yellow);
    }



    70% { 
        opacity: 1;
        background-position: 70% 90%;
        filter: drop-shadow(0px 0px 1px black);
    }
    100% { 
        opacity: 1;
        background-position: 70% 90%;
        filter: drop-shadow(0px 0px 1px black);
    }
`






const StyledHeadline = styled.h1`
    font-family: "lores-9-wide";
    font-weight: 100;
    font-size: ${props => props.fontSize || `28pt`};
    width: 100%;

    background-size: 200% 200%;

    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:row;


       
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(#003f00 0%, #1cdcff 100%);
    background-clip: text;
    -webkit-background-clip: text;

    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    background-size: 200% 200%;

    -webkit-animation: ${fontFlicker} 1.5s linear infinite;
    -moz-animation: ${fontFlicker} 1.5s linear infinite;
    animation: ${fontFlicker} 1.5s linear infinite;
    will-change: auto;
`


export const Headline = ({children, fontSize}) => {

    return(
        <StyledHeadline fontSize={fontSize}>
            {children}
        </StyledHeadline>
    )
}