import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, TextField, Button, Divider, CircularProgress, Zoom, Dialog } from '@material-ui/core';
import nextId from "react-id-generator";
import styled, {keyframes} from 'styled-components';
import { useStore } from "../helpers/GlobalStore";
import { useAoeApiClient } from '../helpers/AoeApiClient';
import { useContractAPI } from "../helpers/ContractAPI";
import BackgroundManager from '../components/BackgroundImageManager';
import ArtLoader from "../assets/CardArtProvider";
import ethImg from "../assets/images/EthImage.png";
import CardPopover from "../components/CardPopover";
import StyledCardContainer from '../components/StyledCardContainer';
import CompendiumUtil from "../pages/compendium/CompendiumUtil";
import { useWindowDimensions } from '../helpers/windowDimentionProvider';
import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import ClaimWinningsPopover from '../components/claim-winnings-modal/ClaimWinningsModal';
import { useAuthReducer } from '../helpers/AuthReducer';

const ClaimButton = styled(Button)`

    color: black;
    font-family: "helvetica"!important;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    background: linear-gradient(#d05000 20%, #ffff00 100%);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    opacity: .75;
    text-align: center;
    margin: auto;
    height: 50px;
    transition: all linear .2s;
    
    &:hover{
        transform: scale(1.1);
        opacity: 1;
        background: linear-gradient(#d05000 0%, #ffff00 90%, #ffffff 100%);
        border: 1px solid white;
        border-left: none;
        border-right: none;
    }
    &:disabled{
        filter: grayscale(1);
    }

`

const nameRadiationFrames = keyframes`
	from {

        filter: drop-shadow(0px 0px 4px #ffcf00);
	}
	to {
	
        filter: drop-shadow(0px 0px 2px #ffcf00);
	}

`


const RadiatingName = styled.div`

    font-family: verdana;
    font-weight: bold;
    font-size: ${props => props.fontSize || `28pt`};
    width: 100%;

    background-size: 200% 200%;

    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:row;

    -webkit-animation: ${nameRadiationFrames} 4s alternate-reverse infinite;
    -moz-animation: ${nameRadiationFrames} 4s alternate-reverse infinite;
    animation: ${nameRadiationFrames} 4s alternate-reverse infinite;
`

const ProfilePage = () => {
    const { width } = useWindowDimensions();
    const {getArt} = ArtLoader();
    const styles = useStyles();
    const {authState} = useAuthReducer();

    var compendiumUtil = CompendiumUtil();
    const { 
        userRoles, userRank, userAddress, 
        myPromoPacks, setMyPromoPacks, lootRewards, setLootRewards,
        packNameDBToUINameMap, currentChainId,
        howOwned, setHowOwned,
        chowOwned, setChowOwned,
        earnedChow, setEarnedChow
    } = useStore()

    const { updateUserName, addPromoCardsToInventory, asyncSyncWalletTokens, getUserRole } = useAoeApiClient()
    const { getPromoManager, getPaymentManager } = useContractAPI()

    const [uNameInput, setUNameInput] = useState("")
    const [uNameError, setUNameError] = useState("")

    const [popover, setPopover] = useState(false)
    const [disableSyncWalletButton, setDisableSyncWalletButton] = useState(false)
    const [showTokensErrorMessage, setShowTokensErrorMessage] = useState(false)
    const [syncTokensErrorMessage, setSyncTokensErrorMessage] = useState("")
    const [showPackPurchasesErrorMessage, setShowPackPurchasesErrorMessage] = useState(false)
    const [syncPackPurchasesErrorMessage, setSyncPackPurchasesErrorMessage] = useState("")
    const [syncingStatus, setSyncingStatus] = useState("")
    const [syncedCards, setSyncedCards] = useState()

    const [selectedPack, setSelectedPack] = useState()
    const [claimQuantity, setClaimQuantity] = useState(1)
    const [canExitModal, setCanExitModal] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [txMsg, setTxMsg] = useState("")


    useEffect(() => {
        if(authState && authState.userName){
            setUNameInput( authState.userName )
        }
    }, [authState])

    useEffect(() => {
        if(userRoles !== null && userRoles !== undefined && userRoles !== '')
            loadPromoManager()
    }, [userRoles])

    const handleClaimChowModalOpen = async () => {
        const winningsResult = await Demodal.open(ClaimWinningsPopover, {dialogueText: "Claim Winnings"})
        
    }

    const hasClosedBetaAccess = () => {
        return userRoles === 'admin' || userRoles === 'betatester';
    }

    const loadPromoManager = async () => {
        getMyPromoPacksAsync()
    }

    const getMyPromoPacksAsync = async () => {
        let promoManager = await getPromoManager()

        if(promoManager === null || promoManager === undefined){
            console.error("Unable to reach the promo manager to retrieve my promo packs")
            return;
        }

        var myPromoPacksResults = await promoManager.getMyPromoPacks()

        if(myPromoPacksResults.length > 0){
            setMyPromoPacks(myPromoPacksResults)
        }
    }

    const getNameErrorHelper = () => {
        if(setUNameInput.length > 45 || setUNameInput < 3)
            return "Display name must be between 3 and 45 characters!"

        else if(uNameError !== "")
            return uNameError

        else return ""
    }

    const handleUpdateUserName = async () => {
        await updateUserName(uNameInput)
            .then(res => {
                if(res === null){
                    setUNameError("Display name taken!")
                }

                else {
                    setUNameError("")
                }
            })
    }

    // Sync the wallet tokens, then the pack purchases, show error messages received in the attempt
    const handleSyncWallet = async () => {
        setDisableSyncWalletButton(true)
        setPopover(true)

        setSyncingStatus("Syncing Tokens on currently connected chain...")
        await asyncSyncWalletTokens((resp) => {
            if(resp && resp.data && resp.data.length > 0)
                setSyncedCards(resp.data)

            setShowTokensErrorMessage(false)
            setSyncTokensErrorMessage("")
            setSyncingStatus("Syncing Complete!")
        }, (err) => {
            setShowTokensErrorMessage(true)
            setSyncTokensErrorMessage(err.message)
            setSyncingStatus("Token Sync Error")
        })


        setDisableSyncWalletButton(false)
    }

    const truncateAddress = () => {

        if (userAddress === undefined || userAddress === null)
            return "";
    
        var addr = "";
        addr = addr.concat(userAddress)
    
        return addr.substring(0,6) + "..." + addr.substring(addr.length - 4, addr.length)
    }

    const getDialogueText = () => {
        if(!showTokensErrorMessage && !showPackPurchasesErrorMessage)
            return syncingStatus

        if(showTokensErrorMessage)
            return syncTokensErrorMessage

        if(showPackPurchasesErrorMessage)
            return syncPackPurchasesErrorMessage
    }

    const getCardRarity = (hash) => {
        let card = compendiumUtil.getCopyOfEntryForCardId(hash)
        return card.rarityId
    }

    const handleMyPromoPackClick = async (pack) => {
        setSelectedPack(pack)
        setPopover(true)
    }

    const swapPackImageNameToCardBackName = (packName) => {
        switch(packName){
            case 'AoE-Common-Pack-Small': return 'common-card-back'
            case 'AoE-Uncommon-Pack-Small': return 'uncommon-card-back'
            case 'AoE-Rare-Pack-Small': return 'rare-card-back'
            case 'AoE-Epic-Pack-Small': return 'epic-card-back'
            case 'AoE-Legendary-Pack-Small': return 'legendary-card-back'
            case 'Promo Pack - Beta': return 'Promo Pack - Beta'
            case 'Promo Pack - Sketch': return 'Promo Pack - Sketch'
            case 'Promo Pack - Sketch': return 'Promo Pack - Sketch'

        }
    }

    const renderMyPromoPackItem = (pack) => {

        var element = 
            <Grid item xl={3} lg={4} md={12} xs={12} sm={12} style={{justifyContent:'center', alignItems:'center', margin: 'auto', padding: 'auto'}}>
                <Grid item  style={{justifyContent:'center', alignItems:'center', margin: 'auto', padding: 'auto', height:'300px', width:'215px', marginTop:'5px', marginBottom:'5px'}}>
                    <StyledCardContainer
                        imgName = {swapPackImageNameToCardBackName(pack.packName)}
                        count = {pack.amountRemaining}
                        onClick = {() => handleMyPromoPackClick(pack)}
                        hover
                    />
                </Grid>

                <Grid item style={{justifyContent:'center', alignItems:'center', margin: 'auto', padding: 'auto'}}>
                    <Typography variant = "h5" className = {`${styles.textWhite} ${styles.textBold} ${styles.textCentered}`}>
                        {packNameDBToUINameMap.get(`${pack.packName}`)}
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
                    <Typography variant = "body2" className = {`${styles.textWhite} ${styles.text} ${styles.textCentered}`}  style={{marginBottom:'30px'}}>
                        {`# Quantity:  ${pack.amountRemaining}`}
                    </Typography>
                </Grid>
            </Grid>
        
        return element
    }

    const handleClaimClick = async () => {

        try{
            //Make the claim request to the promo manager to claim the requested amount
            let promoManager = await getPromoManager()
            let claimRequest = await promoManager.claimPromoPacks(selectedPack.packId, claimQuantity)

            setSelectedPack(undefined)
            setCanExitModal(false)
            setIsProcessing(true)

            const purchaseResult = await claimRequest.wait()

            //Update the visible promo packs and quantities
            getMyPromoPacksAsync()
    
            if(purchaseResult.status === 1){
                const mintedNftsEvent = purchaseResult.events.find((evt) => evt.event === 'NftsBulkMinted');
                
                const newlyMintedIDs = mintedNftsEvent.args['newlyMintedIDs'];
                
                const mintIdStrings = newlyMintedIDs.map(id => {
                    return id.toString()
                })
                
                const addRequest = {
                    PackName: selectedPack.packName,
                    UniqueIds: mintIdStrings
                }
                await addPromoCardsToInventory(addRequest).then(res => {
                    setCanExitModal(true)
                    setIsProcessing(false)

                    //Failure case
                    if(!lootRewards || lootRewards.length === 0){
                        setTxMsg("Add to inventory failed!  Something went wrong on our server. Please try again.")
                    }
                })

            }
            else{
                setCanExitModal(true)
                setIsProcessing(false)
                setTxMsg("Failed to claim promo packs")
                console.error("Failed to claim promo packs on the blockchain.")
            }
            
        }
        catch(err){
            let message;

            if (err && err.error && err.error.message){
                message = err.error.message
            } else if (err && err.message){
                message = err.message
            } else {
                message = "An unknowable error occured"
            }

            message = message.replace("execution reverted: ", "")

            setSelectedPack(undefined)
            setCanExitModal(true)
            setIsProcessing(false)
            setTxMsg(message)
            console.error(message)
        }

    }

    const popoverContents = () => {

        let elements = null

        if(syncingStatus !== "")
            elements = 
            <Grid container className = {styles.loaderContainer}>
                <Grid item  xs={12} style={{marginBottom: 10}}>
                    <Typography 
                    variant = "h3" 
                    style={{
                        textAlign: "center", 
                        textEmphasis: "bold",
                        color: (showTokensErrorMessage || showPackPurchasesErrorMessage) ? "red" : "white", textEmphasis: "bold"
                    }}>
                        {getDialogueText()}
                    </Typography>
                </Grid>

                {!showTokensErrorMessage && !showPackPurchasesErrorMessage && syncingStatus !== "Syncing Complete!" &&
                <Grid item>
                    <CircularProgress/>
                </Grid>
                }
            </Grid>

        if(syncedCards)
            elements = 
            <Grid container className = {styles.syncedItemsContainer}>
                {syncedCards && syncedCards.length > 0 &&
                syncedCards.map(card => (
                    <Grid item className = {styles.cardContainer} key = {`syncedCard-${card.mediaHash}-itemContainer`}>
                        <StyledCardContainer
                        imgName = {card.mediaHash}
                        count = {card.count}
                        rarity = {`${getCardRarity(card.mediaHash)}`}
                        width = "200px"
                        height = "320px"
                        />

                        <Grid item key = {`syncedCard-${card.mediaHash}-textContainer`}>
                            <Typography variant = "h6" style={{textAlign: "center", color:'white'}}>
                                {`Owned:  x${card.count}`}
                            </Typography>
                        </Grid>
                    </Grid>
                ))
                }
            </Grid>

        if (isProcessing){
            elements = 
                <Grid container style={{justifyContent:'center', alignItems:'center', padding: 10, overflowY: "hidden", width: "auto", height: "auto"}}>
                    <Grid item xs={12} style={{marginBottom: 10}}>
                        <Typography variant = "h6" style={{textAlign: "center", color: "white", textEmphasis: "bold"}}>Processing Request</Typography>
                    </Grid>

                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
        }
        //Pack was opened and it's contents are available, so show that
        else if (lootRewards && lootRewards.length > 0){
            elements = 
                <Grid container className = {styles.rewardsContainer }>
                    
                    {
                        lootRewards.map((card, index) => (
                        <Zoom 
                        in = {lootRewards !== undefined && lootRewards.length > 0}
                        style={{
                            transitionDelay: `${150 + ((index + 1) * 200)}ms`
                        }}
                        key = {`lootItem-${card.mediaHash}-Zoom`}
                        >
                            <Grid item key={`lootItem-${card.mediaHash}-MainContainer`}>
                                <Grid container>
                                    <Grid key = {`lootItem-${card.mediaHash}-CardContainer`}  item className = {styles.rewardItem} style={{height:'210px', width:'150px'}}>
                                        <StyledCardContainer
                                            rarity={getCardRarity(card.mediaHash)} 
                                            count={1} 
                                            imgName={card.mediaHash} 
                                            dropShadow
                                        />
                                    </Grid>

                                    <Grid item key={`lootItem-${card.mediaHash}-TextContainer`} xs={12}>
                                    <Typography
                                    variant = "body1"
                                    className = {`${styles.text} ${styles.textCentered}`}
                                    >
                                        {`x ${card.count}`}
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Zoom>
                        ))
                    }
                </Grid>
        }//
        //Pack was clicked on so show the popover for options on this pack
        else if (selectedPack){
            elements = 
                <Grid item className = {styles.popContainer}>
                    <Grid container spacing = {1} style={{justifyContent:'center', alignItems:'center'}}>

                        <Grid item xs={12} style={{marginBottom: 10}}>
                            <Typography variant = "h3" style={{ textAlign: "center" }}>
                                {packNameDBToUINameMap.get(selectedPack.packName)}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant = "body1" style={{textAlign: "center"}}>{selectedPack.description}</Typography>
                        </Grid>

                        <Grid item xs={12} style={{marginBottom: 10}}>
                            <Divider
                            style={{backgroundColor: "white"}}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {(userAddress === null || userAddress === undefined) ?
                            
                            <Typography variant = "h4" style={{textAlign: "center"}}>Login to Claim</Typography>
                            
                            :

                            <Grid container style={{justifyContent:'center', alignItems:'center'}} spacing = {2}>

                                <Grid item>
                                    <Button
                                        className = "buttonRibbon"
                                        variant = "contained"
                                        disabled = {isNaN(+claimQuantity) || Number(claimQuantity) < 1 || claimQuantity > selectedPack.maxClaimablePacksPerTxn}
                                        onClick = {() => handleClaimClick()}
                                    >
                                        Claim
                                    </Button>
                                </Grid>

                                <Grid item style={{ marginRight: 15, marginLeft: 20 }} >
                                    <TextField 
                                        id="standard-textarea"
                                        label = "Quantity"
                                        variant="outlined"
                                        type="number"
                                        className = {styles.numFieldProp}
                                        InputProps = {{className: styles.numFieldProp}}
                                        InputLabelProps = {{className: styles.numFieldLabel}}
                                        value = {claimQuantity}
                                        onChange = {(e) => setClaimQuantity(e.target.value)}
                                    />
                                </Grid>

                                <Grid item>
                                    <Grid container style={{justifyContent:'center', alignItems:'center'}}>
                                        <Grid item >
                                            <img src={ethImg} alt="Ethereum Icon" className={styles.packPriceIcon}/>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant = "h6">{0}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            }
                        </Grid>
                    </Grid>
                </Grid>

        }

        return elements
    }//

    const handlePopoverClose = () => {
        setPopover(false)
        setShowPackPurchasesErrorMessage(false)
        setSyncPackPurchasesErrorMessage("")
        setSyncingStatus("")
        setLootRewards([])
    }

    return (
        <Grid container justifyContent = "center" spacing = {4} style={{alignItems:'center', display:'flex', justifyContent:'center', verticalAlign:'middle', height:'100vh'}}>


            <Grid item style={{pointerEvents: "none"}}>
                <CardPopover
                    rarity = {16}
                    count = {1}
                    contents = {popoverContents()}
                    popover = {popover}
                    closePopover = {() => handlePopoverClose()}
                    dropShadow
                    disableClick = {syncingStatus !== "" && syncingStatus !== "Syncing Complete!"}
                    disableStatus = {syncingStatus === "Syncing Complete!" || showPackPurchasesErrorMessage || showTokensErrorMessage || canExitModal}
                />
            </Grid>

            {userRoles !== undefined && userRoles !== null && userRoles !== ''?
            <Grid item xs={12} style={{marginTop:'8vh'}}>
                <Grid container justifyContent = "center" alignItems = "center">
                    <Grid item style={{margin:"10px"}}>
                        <TextField
                        label = "Display Name"
                        variant = "outlined"
                        size = "small"
                        value = {uNameInput}
                        onChange = {(e) => {setUNameInput(e.target.value); setUNameError("")}}
                        className = {styles.textFieldProp + " " + styles.nameField}
                        InputProps = {{className: styles.textFieldProp}}
                        InputLabelProps = {{className: styles.textFieldLabel}}
                        error = {getNameErrorHelper().length > 0  || uNameInput.length > 45 || uNameInput.length < 3}
                        helperText = {uNameInput.length < 3 ? "User name Must be 3 characters or more": uNameInput.length > 45? 'User name cannot exceed 45 characters': ''}
                        />
                    </Grid>

                    <Grid item style={{margin:"10px"}}>
                        <ClaimButton
                        variant = "contained"
                        className = "buttonRibbon"
                        disabled = {authState.userName && authState.userName.toLowerCase() === uNameInput.toLowerCase() || getNameErrorHelper.length > 0 || uNameInput.length > 45 || uNameInput.length < 3}
                        onClick = {() => handleUpdateUserName()}
                        >
                            Save
                        </ClaimButton>
                    </Grid>

                    <Grid item style={{margin:"10px"}}>
                        <ClaimButton 
                        disabled = { disableSyncWalletButton }
                        onClick = { handleSyncWallet }
                        >
                            Sync Wallet
                        </ClaimButton>
                    </Grid>

                    <Grid item xs={12}>
                    </Grid>

                    <Grid item lg={6} md={8} sm={8} xs={8} className={styles.rankingContainer}>
                        <Grid container style={{justifyContent: "center", alignItems: "center"}}>

                            <Grid item xs={12} style={{display: "flex", marginBottom: 40, justifyContent: "center"}}>
                                <RadiatingName>
                                    {authState && authState.userName ? authState.userName : truncateAddress()}
                                </RadiatingName>
                            </Grid>

                            <Grid container  style={{justifyContent: "center", alignItems: "center", marginBottom:"20px"}}>
                                <Grid container style={{color: "white", textAlign: "left", width:"50%"}}>
                                    <Typography variant="h6" >MMR</Typography>
                                </Grid>

                                <Grid container style={{color: "white", justifyContent: "right", width:"30%"}}>
                                    <Typography variant="h6" >{authState &&  authState.userRank}</Typography>
                                </Grid>
                            </Grid>

                            {/* <Grid container style={{justifyContent: "center", alignItems: "center"}}>
                                <Grid container style={{color: "white", textAlign: "left", width:"50%"}}>
                                    <Typography variant="h6" style={{color: "white", textAlign: "left"}}>$HoW Owned </Typography>
                                </Grid>

                                <Grid container style={{color: "white", justifyContent: "right", width:"30%"}}>
                                   {howOwned}
                                </Grid>
                            </Grid>

                            <Grid container  style={{justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
                                <Grid container style={{color: "white", textAlign: "left", width:"50%"}}>
                                    <Typography variant="h6" style={{color: "white", textAlign: "left"}}>cHoW Owned </Typography>
                                </Grid>

                                <Grid container style={{color: "white", justifyContent: "right", width:"30%"}}>
                                   {chowOwned}
                                </Grid>
                            </Grid>
                            <Grid container  style={{justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
                                <Grid container style={{color: "white", textAlign: "left", width:"50%"}}>
                                    <Typography variant="h6" style={{color: "white", textAlign: "left"}}>Claimable Winnings </Typography>
                                </Grid>

                                <Grid container style={{color: "white", justifyContent: "right", width:"30%"}}>
                                    {earnedChow}
                                </Grid>
                            </Grid>
                            <Grid container  style={{justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
                                <ClaimButton onClick={()=>handleClaimChowModalOpen()}>Claim Winnings</ClaimButton>
                            </Grid> 
                            */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                    </Grid>

                    {(myPromoPacks && myPromoPacks.length > 0 && myPromoPacks.some(pack => pack.amountRemaining > 0)) &&
                        <Grid key={nextId()} item xl={8} lg={8} md={8} sm={8} xs={8} className={styles.promoPacksContainer}
                            style={{
                                width: "auto"
                            }}>

                            <Grid container key={nextId()}
                                style={{
                                    justifyContent:'center', 
                                    alignItems:'center', 
                                }}>

                                <Grid item  key={nextId()} style={{width: '100%'}}>
                                    <Typography  key={nextId()} variant = "h3" className = {`${styles.textWhite} ${styles.textBold} ${styles.textCentered}`}>
                                        Promotional Packs
                                    </Typography>
                                </Grid>

                                {
                                    myPromoPacks.map((pack, index) => (
                                        <div key={nextId()} style={{display:'contents'}}>
                                        {pack.amountRemaining > 0 &&
                                            renderMyPromoPackItem(pack)
                                        }
                                        </div>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    }
                    
                </Grid>
            </Grid>
            :
            <Grid item className={styles.rankingContainer} style={{filter:'drop-shadow(0px 0px 8px #000)'}}>
                <Typography variant = "h2" className = {`${styles.textCentered} ${styles.textWhite}`} >Please sign in to view profile</Typography>
            </Grid>
            }

            <BackgroundManager sourceImage={getArt('ProfileBG')}  blur='4px' opacity='0.4'/>
        </Grid>

    )
}


const useStyles = makeStyles((theme) => ({
    textWhite:{
        color: "white",
        fontFamily: 'verdana'
    },

    textBold:{
        fontWeight: "bold"
    },

    textCentered: {
        textAlign: "center"
    },

    textLeft: {
        textAlign: "left"
    },

    nameField: {
        width: "45ch"
    },

    textFieldProp: {
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    textFieldLabel:{
        color: "white",
        fontSize: 24
    },

    editButton:{
        backgroundColor: "blue",
        color: "white"
    },

    packPriceIcon: {
        height: 20,
        width: 20,
        margin: 0,
        padding: 0,
        backgroundColor: 'transparent'
    },

    rewardsContainer: {
        overflowX: "hidden",
        overflowY: "auto",
        height:'auto',
        width: 'auto',
        padding:'25px',
        background: '#080c0d',
        boxShadow: "none",
        textAlign:"center",
        justifyContent: "center",
        color:'white'
    },

    popContainer: {
        overflowX: "hidden",
        overflowY: "hidden",
        maxWidth: 800,
        width: 'auto',
        height: "auto",
        padding:'10px',
        margin:'0px',
        backgroundColor:'#181c24',
        color:'white',
    },

    rankingContainer:{
        backgroundColor:'rgba(0,0,0,0.75)',
        maxWidth: 900,
        height: "auto",
        borderRadius: 20,
        marginTop: 50,
        paddingTop: 20,
        paddingBottom: 20
    },

    promoPacksContainer:{
        backgroundColor:'rgba(0,0,0,0.75)',
        height: "auto",
        borderRadius: 20,
        marginTop: 50,
        marginBottom: 50
    },

    loaderContainer: {
        justifyContent:'center', 
        alignItems:'center', 
        padding: 10, 
        overflowY: "hidden",
        width: "auto", 
        height: "auto", 
        backgroundColor: "rgba(0,0,0,1)"
    },

    syncedItemsContainer: {
        justifyContent:'center', 
        alignItems:'center', 
        padding: 10, 
        overflowY: "auto",
        overflowX: "hidden",
        maxWidth: "80vw",
        maxHeight: "80vh",
        width: "auto", 
        height: "auto", 
        backgroundColor: "rgba(0,0,0,1)"
    },

    cardContainer: {
        width: 200,
        height: 320,
        margin: 25
    },

    rewardItem: {
        width: 250,
        height: "auto",
        margin: 20
    },

    numFieldProp: {
        height: '36px',
        color: "white",
        background: "rgba(232, 241, 250, 0.05)",
        borderBottom: "1px solid #808080"
    },

    numFieldLabel:{
        color: "white"
    },
    nameOrId:{
        color: "white",

    }

}))


export default ProfilePage;