import GenericButton from './GenericButton'

/*
    cardMetaData = {
        type: 'MONSTER' | 'ITEM'
        attack: int
    }
*/
const Card = (cardId, scene, atlas, artName, artBackName, cardMetaData, onClick) => {
    
    var cardObject;

    var parentContainerId;

    var cardOnClick = onClick;

    //overall flag on this Card to let us know whether it's in the field and has a health to keep track of
    var keepTrackOfHealth = false; 
    var keepTrackOfNumAttacks = false;
    var healthFrame;
    var healthLabel;

    var remainingAttackCount;
    var remainingAttackIcons = [];
    const REMAINING_ATTACK_ICON_OFFSET = 28;

    function destroy() {
        cardObject.destroy();

        if (keepTrackOfHealth){
            healthFrame.destroy();
            healthLabel.destroy();
        }

        if (keepTrackOfNumAttacks){
            for (var i = 0; i < remainingAttackIcons.length; i++) {
                remainingAttackIcons[i].destroy();
            }
        }
    }

    //Sets optional field indicating the id of the parent container this Card resides in (e.g. PlayField, PlayerBanner)
    function setParentContainerId(parentId){
        parentContainerId = parentId;
    }

    function getParentContainerId(){
        return parentContainerId;
    }

    function setPosition(x, y) {
        cardObject.setPosition(x, y);
    }

    function setDepth(z) {
        cardObject.setDepth(z);
    }

    function setCallback(callback){
        //If the card button hasn't been drawn yet, just change the local var which is used to bind during the draw
        if (!cardObject){
            cardOnClick = callback;
        } else {
            cardObject.setCallback(callback);
        }
    }

    function damageCard(damage){
        if (!keepTrackOfHealth){
            console.error("The Card in question isn't keeping track of damage");
            return;
        }
        //Do the math to lose health, capping at 0
        healthLabel.healthValue = Math.max(0, healthLabel.healthValue - damage);
        cardMetaData.remainingHealth = healthLabel.healthValue;

        //Update the health text
        var healthLabelStr = healthLabel.healthValue;
        healthLabel.setText(healthLabelStr);
    }

    function decrementRemainingAttacks(){
        if (!keepTrackOfHealth){
            console.error("The Card in question isn't keeping track of remaining attacks")
            return;
        }

        //Decrease counter by one, redraw all swords to match
        remainingAttackCount -= 1;
        redrawIcons();
    }

    function hasRemainingAttacksThisTurn(){
        return remainingAttackCount > 0;
    }

    function resetRemainingAttacks(){
        remainingAttackCount = thisCard.cardMetaData.maxAttacksPerTurn;
        redrawIcons();
    }

    function redrawIcons(){
        for (var i = 0; i < remainingAttackIcons.length; i++) {
            if (i < remainingAttackCount){
                remainingAttackIcons[i].setVisible(true);
            } else {
                remainingAttackIcons[i].setVisible(false);
            }
        }
    }

    function getUnderlyingImageObjects() {
        var allImageObjects = cardObject.getUnderlyingImageObjects();

        if (keepTrackOfHealth)
            allImageObjects = allImageObjects.concat([ healthFrame, healthLabel ]);

        if (keepTrackOfNumAttacks)
            allImageObjects = allImageObjects.concat(remainingAttackIcons);

        return allImageObjects;
    }

    /**
     * URGENT: The following must appear in this exact order due to the circular'ish 
     * dependencies of draw() to tempCard and tempCard to draw()
     */

    //Is defined now as the context of this Card, so that draw() may bind to it
    var thisCard = {
        cardId: cardId,
        artName: artName,
        cardMetaData: cardMetaData,
        setParentContainerId: setParentContainerId,
        getParentContainerId: getParentContainerId,
        setPosition: setPosition,
        setDepth: setDepth,
        damageCard: damageCard,
        decrementRemainingAttacks: decrementRemainingAttacks,
        hasRemainingAttacksThisTurn: hasRemainingAttacksThisTurn,
        resetRemainingAttacks: resetRemainingAttacks,
        setCallback: setCallback,
        getUnderlyingImageObjects: getUnderlyingImageObjects,
        destroy: destroy
    };

    //binds callback to context of this Card
    function draw(scale, position, drawHealth, drawNumAttacks){
        cardObject && cardObject.destroy();

        cardObject = GenericButton(cardId + "_button", scene, { atlas: atlas, artName: artName }, scale, position, cardOnClick.bind(null, thisCard), artBackName)

        //TODO: hover art, maybe a glow

        const imageDimensions = cardObject.getDimensions();
        const cardWidth = imageDimensions.width;
        const cardHeight = imageDimensions.height;
        const leftEdgeOfCard = position.x - cardWidth/2;
        const topEdgeOfCard = position.y - cardHeight/2;

        if (drawHealth){
            keepTrackOfHealth = true;

            //Determine the location of the health indicator
            const healthLocationX = leftEdgeOfCard + cardWidth*1.05;
            const healthLocationY = topEdgeOfCard + cardHeight*0.05;

            //Determine the starting health, if the cardMetaData comes with remaining health, assign that, otherwise start with the max health
            var startingHealth = thisCard.cardMetaData.remainingHealth ? thisCard.cardMetaData.remainingHealth : thisCard.cardMetaData.health;

            //Get the starting health amount as a string
            var healthLabelStr = startingHealth;
            //Draw health label
            healthFrame = scene.add.image(healthLocationX, healthLocationY, 'banner-health-value-frame').setDepth(position.z + 1).setOrigin(0.5).setScale(scale*0.8);
            healthLabel = scene.add.bitmapText(healthLocationX, healthLocationY-2, 'gothic', healthLabelStr, 80 * scale ).setOrigin(0.5).setDepth(healthFrame.depth + 1);
            healthLabel.healthValue = startingHealth;
        }

        if (drawNumAttacks){
            keepTrackOfNumAttacks = true;

            const swordLocationX = leftEdgeOfCard + cardWidth*(-0.08);
            const swordLocationY = topEdgeOfCard + cardHeight*0.05;

            //Draw the swords up to thisCard.cardMetaData.maxAttacksPerTurn
            remainingAttackCount = thisCard.cardMetaData.maxAttacksPerTurn;
            for (var i = 0; i < remainingAttackCount; i++) {
                var icon = scene.add.image(swordLocationX, swordLocationY+(REMAINING_ATTACK_ICON_OFFSET*i), 'field-sword-icon').setDepth(position.z + 1).setOrigin(0.5).setScale( scale * 2.5);
                icon.removeInteractive();
                remainingAttackIcons.push(icon);
            }

            //Determine whether there is some specified num attacks to START at, if the cardMetaData comes with num attacks
            if (thisCard.cardMetaData.remainingAttacksThisTurn != null){ //NOTE: this check is explicitly "!= null" because we want to allow zero but not 'undefined'
                //Draw down the icons to match
                remainingAttackCount = thisCard.cardMetaData.remainingAttacksThisTurn;
                redrawIcons();
            }
        }
    };

    //Now that draw() is defined and bound to the context, we add draw() to the context as well
    thisCard.draw = draw;

    return thisCard;

}

export default Card;
